import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Store } from '../hooks/main_store';

const PrivateRoute = ({component: Component, ...rest}) => {
  const { state } = React.useContext(Store)
  return <Route
    {...rest}
    render={(props) => state.uid
      ? <Component {...props} />
      : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
  />
}

export default PrivateRoute;
