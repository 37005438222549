import React from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

export default function DialogInput({
  handleClose = () => {},
  onChange2 = () => {},
  onChange = () => {},
  onSubmit = () => {},
  placeHolder2 = '',
  placeHolder = '',
  open = false,
  title = '',
  text = '',
}) {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
        <TextField
          onChange={onChange}
          label={placeHolder}
          margin="dense"
          autoFocus
          fullWidth
        />
        <TextField
          onChange={onChange2}
          label={placeHolder2}
          margin="dense"
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onSubmit} color="primary">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}