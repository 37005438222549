import React from 'react';
import { Box, TextField } from '@material-ui/core';
import {CancelRounded, ArrowDownwardRounded, ArrowUpwardRounded, CheckCircleRounded} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/layout';
import './Updates.scss';
import Model from '../../hooks/Model'
import firebase from '../../utils/firebase'

const Updates = () => {
  const [vc, setVc] = React.useState({
    updates: [],
    title: '',
    text: '',
    image: '',
    url: ''
  })

  const handleChangeNew = (label, value) => {
    if (value.persist) {
      value.persist()
    }
    setVc((ov) => ({...ov, [label]: value.target.value}))
  }

  const handleChange = (index, label, value) => {
    if (value.persist) {
      value.persist()
    }
    const auxArr = [...vc.updates]
    auxArr[index][label] = value.target.value
    setVc((ov) => ({...ov, updates: auxArr}))
  }

  const cleanNew = () => {
    setVc((ov) => ({
      ...ov,
      title: '',
      text: '',
      image: '',
      url: ''
    }))
  }

  const saveNew = () => {
    if (vc.title && vc.text) {
      const auxArr = [{
        title: vc.title,
        text: vc.text,
        url: vc.url,
        image: vc.image
      }, ...vc.updates]
      setVc((ov) => ({...ov, updates: []}))
      setTimeout(() => {
        setVc((ov) => ({
          ...ov,
          updates: auxArr,
          title: '',
          text: '',
          image: '',
          url: ''
        }))
      }, 50);
    }
  }

  const moveItem = (direction, index) => {
    const auxArr = [...vc.updates]
    const auxData = auxArr[index + direction]
    auxArr[index + direction] = auxArr[index]
    auxArr[index] = auxData
    setVc((ov) => ({...ov, updates: auxArr}))
  }

  const deleteItem = (index) => {
    const auxArr = [...vc.updates]
    setVc((ov) => ({...ov, updates: []}))
    setTimeout(() => {
      auxArr.splice(index, 1)
      setVc((ov) => ({...ov, updates: auxArr}))
    }, 50);
  }

  const renderUpdates = () => {
    if (vc.updates.length > 0) {
      return vc.updates.map((data, index) => <Box
        key={index+'-update'}
        className='cont_update'
      >
        <Box className='cont_fields'>
          <TextField
            label="Título"
            variant="outlined"
            className='input_update'
            value={data.title}
            onChange={(val) => handleChange(index, 'title', val)}
          />
          <TextField
            label="Texto"
            variant="outlined"
            className='input_update'
            value={data.text}
            onChange={(val) => handleChange(index, 'text', val)}
          />
          <TextField
            label="URL Image"
            variant="outlined"
            className='input_update'
            value={data.url}
            onChange={(val) => handleChange(index, 'url', val)}
          />
          <TextField
            label="URL Link externo"
            variant="outlined"
            className='input_update'
            value={data.image}
            onChange={(val) => handleChange(index, 'image', val)}
          />
        </Box>
        <Box className='cont_buttons'>
          <CancelRounded
            className='bt_delete'
            onClick={() => deleteItem(index)}
          />
          {index > 0 ? <ArrowUpwardRounded
            className='bt_row'
            onClick={() => moveItem(-1, index)}
          /> : null}
          {index < (vc.updates.length - 1) ? <ArrowDownwardRounded
            className='bt_row'
            onClick={() => moveItem(1, index)}
          /> : null}
        </Box>
      </Box>)
    } else {
      return <Box>
        There aren't updates to show
      </Box>
    }
  }

  const saveInfo = async () => {
    try {
      Model.setData('loading', true)
      await firebase.database().ref('updates').set(vc.updates)
      Model.setData('loading', false)
      alert('Datos guardados correctamente')
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
    }
  }

  const firstLoad = async () => {
    try {
      const response = (await firebase.database().ref('updates').once('value')).val()
      setVc(ov => ({...ov, updates: response || []}))
      Model.setData('loading', false)
      Model.setData('opacity', 0.7)
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', true)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Layout title='Novedades' hideBack>
    <Box className="cont_list_updates">
      <Box className='cont_update'>
        <Box className='cont_fields'>
          <TextField
            label="Título"
            variant="outlined"
            className='input_update'
            value={vc.title}
            onChange={(val) => handleChangeNew('title', val)}
          />
          <TextField
            label="Texto"
            variant="outlined"
            className='input_update'
            value={vc.text}
            onChange={(val) => handleChangeNew('text', val)}
          />
          <TextField
            label="URL Image"
            variant="outlined"
            className='input_update'
            value={vc.url}
            onChange={(val) => handleChangeNew('url', val)}
          />
          <TextField
            label="URL Link externo"
            variant="outlined"
            className='input_update'
            value={vc.image}
            onChange={(val) => handleChangeNew('image', val)}
          />
        </Box>
        <Box className='cont_buttons'>
          <CancelRounded
            className='bt_delete'
            onClick={cleanNew}
          />
          <CheckCircleRounded
            className='bt_row'
            onClick={saveNew}
          />
        </Box>
      </Box>
      {renderUpdates()}
      <Box
        className='save_btn'
        onClick={saveInfo}
      >
        Guardar datos
      </Box>
    </Box>
  </Layout>
}

export default withRouter(Updates);
