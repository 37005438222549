import React from 'react';
import { Box, TextField, Tooltip } from '@material-ui/core';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import Visibility from '@material-ui/icons/Visibility';
import DialogInput from '../../components/dialogInput';
import Snakbar from '../../components/snakbar';
import { withRouter } from 'react-router-dom';
import CallAudio from '../../assets/alert.mp3';
import Layout from '../../components/layout';
import firebase from '../../utils/firebase';
import Save from '@material-ui/icons/Save';
import logo from '../../assets/logo.png';
import Api from '../../hooks/api';
import './MatchesAdmin.scss';
import moment from 'moment';
import _ from 'lodash';
import Model from '../../hooks/Model'

function MatchesAdmin({history}) {
  const [vc, setVc] = React.useState({
    name: '',
    info: {},
    showDialog: false,
    phaseSelected: 0,
    textNotify: '',
    titleNotify: ''
  })
  const [phase, setPhase] = React.useState(-1)
  const [requests, setRequests] = React.useState({})
  const [snak, setSnak] = React.useState({
    openAlert: false,
    variantSnak: 'success',
    messageSnak: '',
  })

  const closeSnak = (value) => {
    setSnak({
      openAlert: value,
      variantSnak: 'success',
      messageSnak: '',
    })
  }

  const changeNotify = (value, label) => {
    value.persist()
    setVc((ov) => ({
      ...ov,
      [label]: value.target.value
    }))
  }

  const changeDate = (event, round, index) => {
    event.persist()
    setVc((ov) => {
      let rounds = ov.info.preview[round]
      rounds[index].date = event.target.value
      return {
        ...ov,
        info: {
          ...ov.info,
          preview: {
            ...ov.info.preview,
            [round]: [ ...rounds ]
          }
        }
      }
    })
  }

  const changeGoals = (event, round, index, date, player) => {
    event.persist()
    setVc((ov) => {
      let rounds = ov.info.preview[round]
      rounds[index][date][player] = event.target.value
      return {
        ...ov,
        info: {
          ...ov.info,
          preview: {
            ...ov.info.preview,
            [round]: [ ...rounds ]
          }
        }
      }
    })
  }

  const changeGoalsGroups = (event, round, date, player) => {
    event.persist()
    setVc((ov) => {
      const refs = round.split('.')
      const rounds = _.get(ov, `info.${round}`, {})
      rounds[date][player] = event.target.value
      return {
        ...ov,
        info: {
          ...ov.info,
          preview: {
            ...ov.info.preview,
            round0: {
            ...ov.info.preview.round0,
            [refs[2]]: rounds
            }
          }
        }
      }
    })
  }

  const goRoom = (room, round, index) => {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')
    window.open(`${window.location.origin}/matchRoom?id=${room}&tournament=${id}&phase=${round}&round=${index}`, '_blank')
  }

  const saveGoalsGroup = async (ref) => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      const response = await Api.saveGoalsGroup({
        uid: id,
        ref,
        data: _.get(vc, `info.${ref}`, {})
      })
      Model.setData('loading', false)
      if (response.status === 200) {
        setSnak({
          openAlert: true,
          variantSnak: 'success',
          messageSnak: 'Marcador guardado',
        })
      } else {
        throw new Error('Error')
      }
    } catch (e) {
      Model.setData('loading', false)
      setSnak({
        openAlert: true,
        variantSnak: 'error',
        messageSnak: 'No se pudo guardar el marcador',
      })
    }
  }

  const saveGoals = async (round, index) => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      const response = await Api.saveGoals({
        uid: id,
        ref: `${round}/${index}`,
        data: vc.info.preview[round][index]
      })
      const response2 = await Api.getTournament(id)
      
      if (response2.status === 200) {
        const auxPhase = phase
        await setPhase(-1)
        await setVc({
          name: _.get(response2, 'data.name', ''),
          info: _.get(response2, 'data', '')
        })
        await setPhase(auxPhase)
      }
      if (response.status === 200) {
        Model.setData('loading', false)
        setSnak({
          openAlert: true,
          variantSnak: 'success',
          messageSnak: 'Marcador guardado',
        })
      } else {
        throw new Error('Error')
      }
    } catch (e) {
      Model.setData('loading', false)
      setSnak({
        openAlert: true,
        variantSnak: 'error',
        messageSnak: 'No se pudo guardar el marcador',
      })
    }
    
  }

  const getCallback = (data) => {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')
    const preview = _.get(data, 'info.preview', {})
    let isRound0 = _.get(data, 'info.preview.round0', null) ? true : false
    return Object.keys(_.get(data, 'info.preview', {})).filter((key) => key.indexOf('round') > -1).forEach((key, index) => {
      if (key !== 'round0') {
        preview[key].forEach((itemRound) => {
          if (!itemRound.first[0] || !itemRound.first[1] || !itemRound.second[0] || !itemRound.second[1])
          firebase.database().ref(`matchroomGamers/${id}/${itemRound.players[0]}CHAT_TO${itemRound.players[1]}_round${isRound0 ? index : index + 1}/contact`).on('value', (snap) => {
            const dataSnap = snap.val()
            const callAudio = document.getElementById('callAudio')
            if (dataSnap) {
              if (callAudio) {
                callAudio.load()
                callAudio.play()
              }
              setRequests((ov) => ({
                ...ov,
                [`${itemRound.players[0]}CHAT_TO${itemRound.players[1]}_round${isRound0 ? index : index + 1}`]: dataSnap
              }))
            } else {
              setRequests((ov) => ({
                ...ov,
                [`${itemRound.players[0]}CHAT_TO${itemRound.players[1]}_round${isRound0 ? index : index + 1}`]: null
              }))
            }
          }, (e) => console.log(e))
        })
      } else {
        preview[key].forEach((itemRound, indexMatch) => {
          const labels = ['first', 'second', 'third']
          if (indexMatch < (preview[key].length / 2)) {
            labels.forEach((auxLabel) => {
              if (!itemRound[auxLabel].first[0] || !itemRound[auxLabel].first[1]) {
                firebase.database().ref(`matchroomGamers/${id}/${itemRound[auxLabel].players[0]}CHAT_TO${itemRound[auxLabel].players[1]}_round0/contact`).on('value', (snap) => {
                  const dataSnap = snap.val()
                  const callAudio = document.getElementById('callAudio')
                  if (dataSnap) {
                    if (callAudio) {
                      callAudio.load()
                      callAudio.play()
                    }
                    setRequests((ov) => ({
                      ...ov,
                      [`${itemRound[auxLabel].players[0]}CHAT_TO${itemRound[auxLabel].players[1]}_round0`]: dataSnap
                    }))
                  } else {
                    setRequests((ov) => ({
                      ...ov,
                      [`${itemRound[auxLabel].players[0]}CHAT_TO${itemRound[auxLabel].players[1]}_round0`]: null
                    }))
                  }
                }, (e) => console.log(e))
              }
            })
          }
        })
      }
    })
  }

  const returnEye = (index) => {
    let flag = false
    Object.keys(requests).forEach((key) => {
      if (key.indexOf(`round${index}`) > -1 && requests[key]) {
        flag = true
      }
    })
    return flag
  }

  const onNotify = (index) => {
    setVc((ov) => ({
      ...ov,
      showDialog: true,
      phaseSelected: index
    }))
  }

  const returnNick = (number) => {
    return _.get(vc, `info.preview.groups[${number}].nickName`, '-')
  }

  const renderMatchGroup = (ref, index, phase) => {
    const match = _.get(vc, `info.${ref}`, null)
    if (!match) {
      return null
    } else {
      return <Box
        key={ref + '-' + index}
        className='cont_match'
      >
        <Box className='players'>
          <Box className='title_col'>
            Grupo {phase} - Partido {index + 1}<br/>
            <span>({match.date ? moment(match.date, 'x').format('DD MMM YYYY h:mm a') : 'Fecha por definir'})</span>
          </Box>
          <Box className='row_player'>
            {returnNick(match.players[0])}
          </Box>
          <Box className='row_player'>
            {returnNick(match.players[1])}
          </Box>
        </Box>
        <Box className='goals'>
          <Box className='title_col'>Marcador</Box>
          <TextField
            label="Goles"
            variant="outlined"
            className="input_goals row_player2"
            defaultValue={match.first[0]}
            onChange={(e) => changeGoalsGroups(e, ref, 'first', '0')}
          />
          <TextField
            label="Goles"
            variant="outlined"
            className="input_goals row_player2"
            defaultValue={match.first[1]}
            onChange={(e) => changeGoalsGroups(e, ref, 'first', '1')}
          />
        </Box>
        <Box className='col_actions'>
          <Tooltip title="Guardar">
            <Save className='icon_check' onClick={() => saveGoalsGroup(ref)} />
          </Tooltip>
          <Tooltip title="Ver sala" onClick={() => goRoom(`${match.players[0]}CHAT_TO${match.players[1]}_round0`, 'round0', index)}>
            <Visibility className={requests[`${match.players[0]}CHAT_TO${match.players[1]}_round0`] ? 'icon_eye red' : 'icon_eye'} />
          </Tooltip>
        </Box>
      </Box>
    }
  }

  const renderGroupsMatches = () => {
    try {
      const groups = []
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const hasGroups = _.get(vc, `info.preview.round0`, '')
      const sizeGroup = _.get(vc, `info.preview.limitUsers`, 0) / 4
      for (let i = 0; i < sizeGroup; i++) {
        groups[i] = letters[i]
      }
      if (!hasGroups) {
        return null
      } else {
        return <Box
          className='section_round'
          style={{height: phase === -2 ? 'auto' : 72}}
        >
          <Box className='cont_section'>
            <Box
              className='title_section'
              onClick={() => setPhase(phase === -2 ? -1 : -2)}
            >
              GRUPOS {returnEye(0) ? <Visibility className='icon_eye red' /> : ''}
            </Box>
            <Box className='btNotify'>
              <Tooltip title="Notificar al grupo">
                <NotificationImportant className='icon_notify' onClick={() => onNotify(-1)} />
              </Tooltip>
            </Box>
          </Box>
          {phase === -2 ? groups.map((group, index) => {
            const refs = [`preview.round0[${index}].first`, `preview.round0[${index + (sizeGroup * 2)}].first`, `preview.round0[${index}].second`, `preview.round0[${index + sizeGroup}].second`, `preview.round0[${index}].third`, `preview.round0[${index + sizeGroup}].third`]
            return refs.map((value, pos) => renderMatchGroup(value, pos, group))
          }) : null}
        </Box>
      }
    } catch (e) {
      return null
    }
  }

  const renderMatches = () => {
    try {
      const preview = _.get(vc, 'info.preview', {})
      const players = _.get(vc, 'info.preview.groups', [])
      return Object.keys(_.get(vc, 'info.preview', {}))
      .filter((key) => key.indexOf('round') > -1 && key !== 'round0').map((key, index) => {
        return <Box
          key={index + '-round'}
          className='section_round'
          style={{height: phase === index ? 'auto' : 72}}
        >
          <Box className='cont_section'>
            <Box
              className='title_section'
              onClick={() => setPhase(phase === index ? -1 : index)}
            >
              FASE {index + 1} {returnEye(index + 1) ? <Visibility className='icon_eye red' /> : ''}
            </Box>
            <Box className='btNotify'>
              <Tooltip title="Notificar al grupo">
                <NotificationImportant className='icon_notify' onClick={() => onNotify(index)} />
              </Tooltip>
            </Box>
          </Box>
          {phase === index ? preview[key].map((itemRound, index2) => {
            // Set callback
            return _.get(players, `${itemRound.players[0]}.nickName`, '') && _.get(players, `${itemRound.players[1]}.nickName`, '') ? 
            <Box
              key={key + '-round-' + index + '-' + index2}
              className='cont_match'
            >
              <Box className='players'>
                <Box className='title_col'>
                  Llave {index2 + 1}<br/>
                </Box>
                <Box className='row_player'>
                  {_.get(players, `${itemRound.players[0]}.nickName`, '')}
                </Box>
                <Box className='row_player'>
                  {_.get(players, `${itemRound.players[1]}.nickName`, '')}
                </Box>
                <TextField
                  className='item_date'
                  label={itemRound.date ? moment(itemRound.date, 'x').format('DD MMM YYYY h:mm a') : 'Fecha por definir'}
                  variant="outlined"
                  defaultValue={itemRound.date}
                  onChange={(e) => changeDate(e, key, index2)}
                />
              </Box>
              <Box className='goals'>
                <Box className='title_col'>Fecha 1</Box>
                <TextField
                  label="Goles"
                  variant="outlined"
                  className="input_goals row_player"
                  defaultValue={itemRound.first[0]}
                  onChange={(e) => changeGoals(e, key, index2, 'first', '0')}
                />
                <TextField
                  label="Goles"
                  variant="outlined"
                  className="input_goals row_player"
                  defaultValue={itemRound.first[1]}
                  onChange={(e) => changeGoals(e, key, index2, 'first', '1')}
                />
              </Box>
              <Box className='goals'>
                <Box className='title_col'>Fecha 2</Box>
                <TextField
                  label="Goles"
                  variant="outlined"
                  className="input_goals row_player"
                  defaultValue={itemRound.second[0]}
                  onChange={(e) => changeGoals(e, key, index2, 'second', '0')}
                />
                <TextField
                  label="Goles"
                  variant="outlined"
                  className="input_goals row_player"
                  defaultValue={itemRound.second[1]}
                  onChange={(e) => changeGoals(e, key, index2, 'second', '1')}
                />
              </Box>
              <Box className='col_actions'>
                <Tooltip title="Guardar">
                  <Save className='icon_check' onClick={() => saveGoals(key, index2)} />
                </Tooltip>
                <Tooltip title="Ver sala" onClick={() => goRoom(`${itemRound.players[0]}CHAT_TO${itemRound.players[1]}_round${index + 1}`, key, index2)}>
                  <Visibility className={requests[`${itemRound.players[0]}CHAT_TO${itemRound.players[1]}_round${index + 1}`] ? 'icon_eye red' : 'icon_eye'} />
                </Tooltip>
              </Box>
            </Box> : null
          }) : null}
        </Box>
      })
    } catch (e) {
      return null
    }
  }

  const onSubmitNotify = async () => {
    try {
      if (!vc.titleNotify || !vc.textNotify) {
        setSnak({
          openAlert: true,
          variantSnak: 'error',
          messageSnak: 'No dejes campos vacíos',
        })
      } else {
        Model.setData('loading', true)
        setVc((ov) => ({
          ...ov,
          showDialog: false
        }))
        const round = vc.phaseSelected < 0 ? _.get(vc, `info.preview.groups`, []) : _.get(vc, `info.preview.round${vc.phaseSelected + 1}`, [])
        const urlParams = new URLSearchParams(window.location.search)
        const id = urlParams.get('id')
        const response = await Api.sendNotificationPhase({
          uids: vc.phaseSelected < 0 ? round.map((user, ind) => String(ind)) : round.map((matches) => matches.players),
          title: vc.titleNotify,
          text: vc.textNotify,
          tournament: id,
        })
        if (response.ok && response.status === 200) {
          Model.setData('loading', false)
          setVc((ov) => ({
            ...ov,
            showDialog: false,
            titleNotify: '',
            textNotify: ''
          }))
          setSnak({
            openAlert: true,
            variantSnak: 'success',
            messageSnak: 'Notificación enviada',
          })
        } else {
          throw new Error('Fail')
        }
      }
    } catch (e) {
      Model.setData('loading', false)
      setSnak({
        openAlert: true,
        variantSnak: 'error',
        messageSnak: 'Problemas de conexión',
      })
    }
  }

  const firstLoad = async () => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      const response = await Api.getTournament(id)
      
      if (response.status === 200) {
        await setVc({
          name: _.get(response, 'data.name', ''),
          info: _.get(response, 'data', '')
        })
        await Model.setData('loading', false)
        getCallback({
          info: _.get(response, 'data', '')
        })
      } else {
        throw new Error('Fail')
      }
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    firstLoad()
    Model.setData('loading', true)
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Layout title='Partidos' subroute='/matchesAdmin'>
    <Box className="cont_matches_players">
      <audio id="callAudio">
        <source src={CallAudio} type="audio/mpeg"/>
      </audio>
      <Box className="matches_players">
        <Box className='cont_info'>
          <img alt='tournament' src={logo} className='bg_banner' />
          <Box className='cont_title'>
            <p className='title'> Encuentros del torneo {vc.name} </p>
          </Box>
        </Box>
        <Box className='row_list'>
          {renderGroupsMatches()}
          {renderMatches()}
        </Box>
      </Box>
      <Snakbar
        message={snak.messageSnak}
        open={snak.openAlert}
        handleClose={() => closeSnak(false)}
        variant={snak.variantSnak}
      /> 
    </Box>
    <DialogInput
      text='Ingresa el título y texto de la notificación que deseas enviar al grupo. Recuerda que esta acción no se puede revertir y le llegará a todos los usuarios de esta fase. (NOTA: Si no les llegan los mensajes a los usuarios es porque tienen deshabilitada las notificaciones en la App)'
      handleClose={() => setVc((ov) => ({...ov, showDialog: false}))}
      onChange={(e) => changeNotify(e, 'titleNotify')}
      onChange2={(e) => changeNotify(e, 'textNotify')}
      placeHolder='Título'
      placeHolder2='Texto'
      title={vc.phaseSelected < 0 ? 'Enviar notificación a Grupos' : `Enviar notificación a Fase ${vc.phaseSelected + 1}`}
      open={vc.showDialog}
      onSubmit={onSubmitNotify}
    />
  </Layout>
}

export default withRouter(MatchesAdmin);
