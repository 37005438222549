import React from 'react';
import { CircularProgress, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { setCurrency } from '../../utils/validate';
import Snakbar from '../../components/snakbar';
import Api from '../../hooks/api';
import moment from 'moment';
import './Payment.scss';
import _ from 'lodash';
import Model from '../../hooks/Model'
export default function Payment() {
  const [showTest, setShowTest] = React.useState(false)
  const [vc, setVc] = React.useState({
    openAlert: false,
    variantSnak: 'error',
    messageSnak: '',
  })
  const [allReq, setAllReq] = React.useState({ price: 0 })
  const [varForm, setVarForm] = React.useState({
    name: '',
    observa: '',
    terms: '',
    contactData: ''
  })
  const [loading, setLoading] = React.useState(true)
  const [errorMsg, setErrorMsg] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [textTitle, setTextTitle] = React.useState('Antes de proceder con el pago necesitamos que ingreses los siguientes datos para brindarte la mejor experiencia durante tu participación:')
  const [btPay, setBtPay] = React.useState('Ir a Pagar')
  const [showMsg, setShowMsg] = React.useState(true)
  const [showChecks, setShowChecks] = React.useState(false)
  const [keyMp, setKeyMp] = React.useState('')
  const [mercadoPago, setMercadoPago] = React.useState(null)
  
  const [allChecks, setAllChecks] = React.useState({
    promise: false,
    available: false,
    allpromise: false
  })

  const cancelProcess = () => {
    if (window.confirm("¿Deseas cancelar tu proceso de inscripción?")) {
      window.location.replace('https://laredfutbolera.com')
    }
  }

  const changeCheck = (ref, e) => {
    if (e.persist) {
      e.persist()
    }
    setAllChecks((ov) => ({
      ...ov,
      [ref]: e.target.checked
    }))
  }

  const sendChecks = () => {
    const {
      promise,
      available,
      allpromise
    } = allChecks
    if (promise && available && allpromise) {
      setShowChecks(false)
      submitForm()
    } else {
      setVc({
        variantSnak: 'error',
        messageSnak: 'Debes aceptar todos los puntos para continuar',
        openAlert:  true
      })
    }
  }

  const changeInput = (e, ref) => {
    if (e.persist) {
      e.persist()
    }
    setVarForm((ov) => ({
      ...ov,
      [ref]: e.target.value
    }))
  }

  const remove = (id) => {
    if (document.getElementById(id)) {
      document.getElementById(id).remove()
    }
  }

  const setStyle = (id, prop, value) => {
    if (document.getElementById(id)) {
      document.getElementById(id).style[prop] = value
    }
  }

  const preSubmit = () => {
    if (!varForm.name || !varForm.observa || !varForm.contactData) {
      setVc({
        variantSnak: 'warning',
        messageSnak: 'Ingresa todos los datos',
        openAlert:  true
      })
    } else if (!varForm.terms || varForm.terms === 'false') {
      setVc({
        variantSnak: 'warning',
        messageSnak: 'Acepta los Términos y Condiciones para continuar',
        openAlert:  true
      })
    } else {
      setAllChecks({
        promise: false,
        available: false,
        allpromise: false
      })
      setShowChecks(true)
    }
  }

  const renderDate = () => {
    const date = _.get(allReq, 'date', {})
    return `${date.day}/${date.month}/${date.year} - ${allReq.hour}`
  }

  const submitForm = async () => {
    try {
      // Get the action to complete.
      const urlParams = new URLSearchParams(window.location.search)
      const isTest = urlParams.get('isTest') || 'no';
      const booking = urlParams.get('booking');
      const data = {
        isTest,
        ...varForm,
        booking,
      };

      setStyle('allinfo', 'display', 'none')
      setStyle('rowbtns', 'display', 'none')
      setStyle('msg', 'display', 'flex')
      setLoading(true)

      const response = await Api.verifyBook(data)
      if (!response.ok || response.status !== 200 || response.data.status !== 'waiting') {
        setTitle('No se puede procesar tu reserva')
        setTextTitle('La reserva ya no puede ser realizada porque no se encuentra disponible.')
        setStyle('msg', 'display', 'none')
        setLoading(false)
      } else {
        // Go to Pay
        Api.post('/bookings/generatePreferenceMp', {
          uidCancha: allReq.uidCancha,
          preference: {
            "items": [
              {
                "id": booking,
                "title": "PAGO RESERVA CANCHA LA RED FUTBOLERA",
                "currency_id": "COP",
                "picture_url": "https://firebasestorage.googleapis.com/v0/b/la-red-futbolera-app.appspot.com/o/others%2FLOGO_APP.png?alt=media&token=555911da-bb30-464f-a6e3-18d5cdd86d65",
                "description": `Pago de reserva para la cancha ${_.get(allReq, 'stadium', '')}, Aliada de La Red Futbolera`,
                "category_id": "services", // Available categories at https://api.mercadopago.com/item_categories
                "quantity": 1,
                "unit_price": parseInt(_.get(allReq, 'price', 0))
              }
            ],
            "marketplace_fee": (parseInt(_.get(allReq, 'price', 0)) * 0.0471) - 300,
            "payer": {
              "name": varForm.name,
              "surname": varForm.name,
              // "email": _.get(allReq, 'email', ''),
              "phone": {
                "area_code": "57",
                "number": parseInt(varForm.contactData)
              },
              // "identification": {
              //   "type": "CI", // Available ID types at https://api.mercadopago.com/v1/identification_types
              //   "number": varForm.contactData
              // },
              // "address": {
              //   "street_name": "Street",
              //   "street_number": 123,
              //   "zip_code": "5700"
              // }
            },
            "back_urls": {
              "success": "https://laredfutbolera.com/paymentMPBooking",
              "failure": "https://laredfutbolera.com/paymentMPBooking",
              "pending": "https://laredfutbolera.com/paymentMPBooking"
            },
            "auto_return": "approved",
            "payment_methods": {
              // "excluded_payment_methods": [
              //   {
              //     "id": "master"
              //   }
              // ],
              "excluded_payment_types": [
                {
                  "id": "ticket"
                },
                {
                  "id": "atm"
                }
              ],
              "installments": 12,
            },
            // "shipments": {
            //   "receiver_address": {
            //     "zip_code": "5700",
            //     "street_number": 123,
            //     "street_name": "Street",
            //     "floor": 4,
            //     "apartment": "C"
            //   }
            // },
            "notification_url": "https://laredfutboleraserver.appspot.com/bookings/ipnMercadoPago",
            "statement_descriptor": "PAGO_LRF",
            "external_reference": booking,
            "expires": true,
            "expiration_date_from": String(moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')).replace('Z', '-05:00'),
            "expiration_date_to": String(moment().add(15, 'minutes').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')).replace('Z', '-05:00'),
            // "taxes": [
            //   {
            //     "type": "IVA",
            //     "value": 16
            //   }
            // ]
          }
        }).then((dataResponse) => {
          if (dataResponse.ok && dataResponse?.data?.idPreference && mercadoPago) {
            setTitle('¡Hecho!')
            setTextTitle('Ahora serás redireccionado a la página de pagos.')
            setTimeout(() => {
              mercadoPago.checkout({
                preference: {
                  id: dataResponse.data.idPreference
                },
                autoOpen: true
              });
            }, 1000);
          } else {
            setTitle('No se puede procesar tu reserva')
            setTextTitle('La reserva ya no puede ser realizada porque no se encuentra disponible. [Error: No preference]')
          }
          setLoading(false)
        }).catch(() => {
          setLoading(false)
          setTitle('No se puede procesar tu reserva')
          setTextTitle('La reserva ya no puede ser realizada porque no se encuentra disponible. [Error: No preference]')
        })
      }
      setStyle('msg', 'display', 'none')
    } catch (e) {
      setLoading(false)
      setTitle('No se puede procesar tu reserva')
      setTextTitle('La reserva ya no puede ser realizada porque no se encuentra disponible.')
      setStyle('msg', 'display', 'none')
    }
  }

  const firstLoad = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const booking = urlParams.get('booking');
      const isTest = urlParams.get('isTest') || 'no';
      const payment_id = urlParams.get('payment_id');
      const external_reference = urlParams.get('external_reference');
      setShowTest(isTest === 'yes')
      if (payment_id) {
        const response = await Api.post('/bookings/ipnMercadoPago', {id: payment_id, topic: 'payment'})
        if (_.get(response, 'data.status', '')  === 'approved') {
          setTitle('Transacción completada ID: ' + external_reference);
          setTextTitle('Gracias por confiar en La Red Futbolera. ATENCIÓN futboler@ ya quedaste inscrit@ a la reserva, pronto recibirás un email con las próximas instrucciones (Revisar en bandeja de entrada o Correo no deseado si no lo encuentras). Si no te llega el email o tienes algún inconveniente puedes escribirnos a soporte@laredfutbolera.com ¡Nos vemos en las canchas, prepárate!');
        } else if (_.get(response, 'data.status', '')  === 'pending' 
          || _.get(response, 'data.status', '')  === 'authorized'
          || _.get(response, 'data.status', '')  === 'in_process') {
          setTitle('Transacción pendiente ID: ' + external_reference);
          setTextTitle('Aún estamos procesando la transacción y esperando respuesta, recarga la página en unos minutos para revisar nuevamente. Si tienes algún inconveniente puedes escribirnos a soporte@laredfutbolera.com');
          setTimeout(firstLoad, 20000); // Veiry again payment
        } else {
          setTitle('Transacción rechazada o con problemas ID: ' + external_reference);
          setTextTitle('Lamentamos que no se haya podido completar tu pago. Si tienes problemas no dudes en escribirnos al correo soporte@laredfutbolera.com.');
          // throw new Error('Fail')
        }
        setShowMsg(false)
      } else if (booking) {
        if (isTest === 'no') {
          setKeyMp("APP_USR-a7560887-03ee-411e-bdef-966957f22ac5")
        } else {
          setKeyMp("TEST-bc3d7d74-85f8-4715-96c6-7d189a6054be")
        }
        const response = await Api.verifyBook({booking})
        try {
          const alldata = {...response.data}
          setAllReq(alldata)
          if (!response.ok || response.status !== 200 || alldata.status !== 'waiting') {
            setAllReq(response.data || {})
            throw new Error(response.data || 'Error')
          } else {
            // console.log(alldata)
            setBtPay(`Ir a pagar ${setCurrency(_.get(alldata, 'price', 0))}`)
            setTextTitle('Antes de proceder con la reserva necesitamos que ingreses los siguientes datos para brindarte la mejor experiencia:');

            setStyle('allinfo', 'display', 'block')
            setStyle('rowbtns', 'display', 'flex')
          }
        } catch (e) {
          remove('allinfo')
          setTitle('La reserva ya no está disponible');
          setTextTitle((e?.message === 'expired' ? 'Su reserva se ha vencido, debe generar nuevamente una reserva en la aplicación.' : 'Pueda que ya se haya reservado y la hora no esté disponible.') + ' Si tienes problemas con tu reserva escribe un correo a soporte@laredfutbolera.com');
        }
        setShowMsg(false)
      } else {
        setTitle('Error desconocido');
        setTextTitle('No se puede procesar tu solicitud');
        setShowMsg(false)
      }
      setLoading(false)
    } catch (e) {
      setErrorMsg('Problemas de conexión, vuelve a intentarlo más tarde.')
    }
  }

  React.useEffect(() => {
    if (keyMp) {
      const script = document.createElement('script')
      script.src = 'https://sdk.mercadopago.com/js/v2'
      script.addEventListener('load', () => {
        setMercadoPago(new window.MercadoPago(keyMp, {
          locale: 'es-CO'
        }))
      })
      document.body.appendChild(script)
    }
    return () => {}
    //eslint-disable-next-line
  }, [keyMp])

  React.useEffect(() => {
    Model.setData('loading', false)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Box className="cont_payment_tournament">
    <Box className="header shadow-1">
      LRF - PAGO RESERVA CANCHA {showTest ? '(TEST MODE)' : ''}
    </Box>
    <Box className="info" id="infoform">
      <h3 id="title">
        {title || `Formulario de inscripción ${_.get(allReq, 'tournament.name', '')}`}
      </h3>
      <p id="text">
        {textTitle}
      </p>
      <Box id="allinfo">
        <p>Correo electrónico: <b><span>
          {_.get(allReq, 'email', '')}
        </span></b><br/></p>
        <p>Cancha: <b><span>
          {_.get(allReq, 'stadium', '')}
        </span></b><br/></p>
        <p>Fecha: <b><span>
          {renderDate()}
        </span></b><br/></p>
        <p>Nombre completo</p>
        <input
          autoComplete="off"
          value={varForm.name}
          onChange={(e) => changeInput(e, 'name')}
        />
        <p>Comentarios</p>
        <input
          autoComplete="off"
          value={varForm.observa}
          onChange={(e) => changeInput(e, 'observa')}
          placeholder="Cumpleaños, reunión de trabajo, etc..."
        />
        <p id="lblContact">Celular</p>
        <input
          autoComplete="off"
          value={varForm.contactData}
          onChange={(e) => changeInput(e, 'contactData')}
        />
        <Box className='row_radios'>
          <Checkbox
            checked={varForm.terms === 'true'}
            onChange={(e) => changeInput({
              target: {
                value: e.target.checked ? 'true' : 'false'
              }
            }, 'terms')}
            color='primary'
          />
          <Box>
            Acepto los Términos y Condiciones <a
              href='https://www.laredfutbolera.com'
              rel="noopener noreferrer"
              target='_blank'
            >(Ver aquí)</a>
          </Box>
        </Box>
        <p className="note" id="notePayu"><span role="img" aria-label="lock">🔒</span> Para brindarles la mejor seguridad y confianza a nuestros usuarios, los pagos se realizan por medio de la plataforma <a href="https://www.mercadopago.com.co/" rel="noopener noreferrer" target='_blank'>Mercado Pago</a>, por lo tanto, La Red Futbolera no almacena ni manipula ninguna información sensible de los métodos de pagos de los usuarios.
        </p>
        <p className="note" id="noteRemeber"><b>RECUERDA:</b> Solo dispones de 10 minutos máximo para realizar el pago y asegurar tu reserva, pasado el tiempo tu reserva se tomará como vencida y se liberará el cupo. Si presentas problemas puedes escribir un correo a <span>soporte@laredfutbolera.com</span></p>
        <button onClick={preSubmit} id="btPay">
          {btPay}
        </button>
      </Box>
      {showMsg ? <Box className="msg" id="msg">
        Cargando ...
      </Box> : null}
    </Box>
    <Box className="footer">COPYRIGHT © LAREDFUTBOLERA 2021</Box>
    {loading ? <Box className='cont_loading'>
      {errorMsg ? <Box className='error_msg'>
        {errorMsg}<br/>
        Att: La Red Futbolera Gamers
      </Box> : <CircularProgress color='primary' />}
    </Box> : null}
    <Snakbar
      message={vc.messageSnak}
      open={vc.openAlert}
      handleClose={() => setVc({
        ...vc,
        openAlert: false
      })}
      variant={vc.variantSnak}
    />
    {showChecks ? <Box className='modalChecks'>
      <Box className='cont_checks'>
        <Box className='intro_checks'>
          Antes de continuar debes aceptar los siguientes puntos, si no cumples con alguno lo recompendable es que canceles tu proceso ya que puede causar la cancelación de tu reserva sin reembolsos:
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.available}
              onChange={(e) => changeCheck('available', e)}
              color="primary"
            />
          }
          label='Llegaré a tiempo para recibir mi reserva y disfrutar de los servicios de la cancha.'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.promise}
              onChange={(e) => changeCheck('promise', e)}
              color="primary"
            />
          }
          label="Me comprometo a ofrecer un juego limpio y respetuoso durante mi estadia en la cancha."
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.allpromise}
              onChange={(e) => changeCheck('allpromise', e)}
              color="primary"
            />
          }
          label="Al aceptar y realizar el pago de la reserva no se aceptarán devoluciones por incumplimiento a los términos, condiciones y los puntos anteriormente mencionados."
        />
        <button onClick={sendChecks}>
          Aceptar y pagar
        </button>
        <button className='nobg' onClick={cancelProcess}>
          Cancelar
        </button>
        <button className='noborder' onClick={() => setShowChecks(false)}>
          Volver
        </button>
      </Box>
    </Box> : null}
  </Box>
}
