import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles({
  root: {
    background: 'white',
    width: '100vw',
    height: '100vh'
  },
  form: {
    width: '90%',
    maxWidth: 300,
    margin: '30px auto',
    border: '1px solid #E4E4E4',
    boxShadow: '5px 10px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: 12,
    padding: 20,
    position: 'relative'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 18
  },
  input: {
    marginBottom: 18
  },
  button: {
    backgroundColor: colors.green,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 16,
    padding: '12px 0px',
    borderRadius: 12,
    cursor: 'pointer',
    "&:hover": {
      opacity: 0.7
    }
  },
  contLoader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    zIndex: 1
  }
});

export default useStyles;