import React from 'react';
import { Box, TextField } from '@material-ui/core';
import {CancelRounded, /*ArrowDownwardRounded, ArrowUpwardRounded,*/ CheckCircleRounded, Save, Lock, LockOpen} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/layout';
import './GuessScore.scss';
import Model from '../../hooks/Model'
import firebase from '../../utils/firebase'
import momentAux from 'moment-timezone'
import _ from 'lodash'

const timezone = 'America/Bogota';
const moment = (data, format) => {
  if (data && format) {
    return momentAux.tz(data, format, timezone);
  } else if (data) {
    return momentAux.tz(data, timezone);
  } else {
    return momentAux.tz(timezone);
  }
};

const Updates = () => {
  const [vc, setVc] = React.useState({
    updates: [],
    local: {
      logo: '',
      name: ''
    },
    visit: {
      logo: '',
      name: ''
    },
    date: '',
    points: '',
    event: '',
    showNew: false,
  })

  const returnFormat = (date) => {
    if (date) {
      return `${moment(date, 'x').format('YYYY-MM-DD')}T${moment(date, 'x').format('HH:mm')}`
    } else {
      return date
    }
  }

  const handleChangeNew = (label, value) => {
    if (value.persist) {
      value.persist()
    }
    if (label === 'date') {
      setVc((ov) => ({...ov, [label]: moment(value.target.value).format('x')}))
    } else {
      const indexs = label.split('.')
      if (indexs.length > 1) {
        setVc((ov) => ({...ov, [indexs[0]]: {
          ...ov[indexs[0]],
          [indexs[1]]: value.target.value
        }}))
      } else {
        setVc((ov) => ({...ov, [label]: value.target.value}))
      }
    }
  }

  const handleChange = (index, label, value) => {
    if (value.persist) {
      value.persist()
    }
    const auxArr = [...vc.updates]
    if (label === 'date') {
      auxArr[index][label] = moment(value.target.value).format('x')
    } else {
      auxArr[index][label] = value.target.value
    }
    setVc((ov) => ({...ov, updates: auxArr}))
  }

  const cleanNew = () => {
    setVc((ov) => ({
      ...ov,
      local: {
        logo: '',
        name: ''
      },
      visit: {
        logo: '',
        name: ''
      },
      date: '',
      points: '',
      event: '',
      showNew: false
    }))
  }

  const saveNew = async () => {
    try {
      if (vc.date && vc.points && vc.event) {
        Model.setData('loading', true)
        const idRef = moment().format('x');
        await firebase.database().ref('guessScore').child(idRef).set({
          points: parseInt(vc.points),
          date: vc.date,
          event: vc.event,
          local: vc.local,
          visit: vc.visit,
          closed: false,
          end: false,
          finalLocal: '',
          finalVisit: ''
        })
        Model.setData('loading', false);
        alert('Datos guardados correctamente')
        const auxArr = [{
          points: vc.points,
          date: vc.date,
          event: vc.event,
          local: vc.local,
          visit: vc.visit,
        }, ...vc.updates]
        setVc((ov) => ({...ov, updates: []}))
        setTimeout(() => {
          setVc((ov) => ({
            ...ov,
            updates: auxArr,
            local: {
              logo: '',
              name: ''
            },
            visit: {
              logo: '',
              name: ''
            },
            date: '',
            points: '',
            event: '',
            showNew: false
          }))
        }, 50);
      }
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
    }
  }

  // const moveItem = (direction, index) => {
  //   const auxArr = [...vc.updates]
  //   const auxData = auxArr[index + direction]
  //   auxArr[index + direction] = auxArr[index]
  //   auxArr[index] = auxData
  //   setVc((ov) => ({...ov, updates: auxArr}))
  // }

  const changeStatus = async (index) => {
    try {
      const auxVals = vc.updates[index]
      await firebase.database().ref('guessScore').child(auxVals.uid).update({
        closed: auxVals.closed ? false : true
      })
      firstLoad()
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
    }
  }

  const saveItem = async (index) => {
    try {
      const auxItem = {}, auxVals = vc.updates[index]
      Object.keys(auxVals).forEach((key) => {
        const keys = key.split('.')
        if (keys.length > 1) {
          auxItem[keys[0]] = auxVals[keys[0]] || {}
          auxItem[keys[0]][keys[1]] = auxVals[key]
        } else {
          auxItem[key] = auxVals[key]
        }
      })
      await firebase.database().ref('guessScore').child(auxItem.uid).update(auxItem)
      if (auxItem.finalVisit && auxItem.finalLocal && auxItem.closed && !auxItem.end) {
        const optionSelected = window.confirm("¿Quieres otorgar los puntos?");
        if (optionSelected) {
          const auxItem = vc.updates[index]
          const response = await Model.getWinnersGuessScore({id: auxItem.uid})
          if (response.ok) {
            // Done
          } else {
            throw new Error('Server failed')
          }
        }
      }
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
    }
  }

  const deleteItem = async (index) => {
    try {
      const response = window.confirm("¿Deseas eleminar este partido?");
      if (response) {
        const auxItem = vc.updates[index]
        await firebase.database().ref('guessScore').child(auxItem.uid).remove()
        firstLoad()
      }
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
    }
  }

  const renderUpdates = () => {
    if (vc.updates.length > 0) {
      return vc.updates.map((data, index) => <Box
        key={index+'-update'}
        className='cont_update'
      >
        <Box className='cont_fields'>
          <Box className='row_imgs'>
            <img
              className='img_team'
              src={_.get(data, 'local.logo', '')}
              alt='Local'
            />
            <img
              className='img_team'
              src={_.get(data, 'visit.logo', '')}
              alt='Visit'
            />
          </Box>
          <TextField
            label="Nombre local"
            variant="outlined"
            className='input_update'
            value={_.get(data, 'local.name', '')}
            onChange={(val) => handleChange(index, 'local.name', val)}
          />
          <TextField
            label="Nombre visitante"
            variant="outlined"
            className='input_update'
            value={_.get(data, 'visit.name', '')}
            onChange={(val) => handleChange(index, 'visit.name', val)}
          />
          <TextField
            label="Imagen local"
            variant="outlined"
            className='input_update'
            value={_.get(data, 'local.logo', '')}
            onChange={(val) => handleChange(index, 'local.logo', val)}
          />
          <TextField
            label="Imagen visitante"
            variant="outlined"
            className='input_update'
            value={_.get(data, 'visit.logo', '')}
            onChange={(val) => handleChange(index, 'visit.logo', val)}
          />
          <TextField
            label="Goles local"
            variant="outlined"
            className='input_update'
            value={data.finalLocal}
            onChange={(val) => handleChange(index, 'finalLocal', val)}
          />
          <TextField
            label="Goles visitante"
            variant="outlined"
            className='input_update'
            value={data.finalVisit}
            onChange={(val) => handleChange(index, 'finalVisit', val)}
          />
          <TextField
            label="Nombre evento"
            variant="outlined"
            className='input_update2'
            value={data.event}
            onChange={(val) => handleChange(index, 'event', val)}
          />
          <TextField
            label="Fecha"
            type="datetime-local"
            value={returnFormat(data.date)}
            className='input_update'
            onChange={(val) => handleChange(index, 'date', val)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            label="Puntos a dar"
            variant="outlined"
            className='input_update'
            value={data.points}
            onChange={(val) => handleChange(index, 'points', val)}
          />
        </Box>
        <Box className='cont_buttons'>
          {!data.closed ? <LockOpen
            className='bt_lock'
            onClick={() => changeStatus(index)}
          /> : <Lock
            className='bt_lock'
            onClick={() => changeStatus(index)}
          />}
          <Save
            className='bt_save'
            onClick={() => saveItem(index, data)}
          />
          <CancelRounded
            className='bt_delete'
            onClick={() => deleteItem(index)}
          />
          {/* {index > 0 ? <ArrowUpwardRounded
            className='bt_row'
            onClick={() => moveItem(-1, index)}
          /> : null}
          {index < (vc.updates.length - 1) ? <ArrowDownwardRounded
            className='bt_row'
            onClick={() => moveItem(1, index)}
          /> : null} */}
        </Box>
      </Box>)
    } else {
      return <Box className='empty_text'>
        No hay partidos para mostrar
      </Box>
    }
  }

  const renderNew = () => {
    if (vc.showNew) {
      return <Box className='cont_update'>
        <Box className='cont_fields'>
          <Box className='row_imgs'>
            <img
              className='img_team'
              src={vc.local.logo}
              alt='Local'
            />
            <img
              className='img_team'
              src={vc.visit.logo}
              alt='Visit'
            />
          </Box>
          <TextField
            label="Nombre local"
            variant="outlined"
            className='input_update'
            value={vc.local.name}
            onChange={(val) => handleChangeNew('local.name', val)}
          />
          
          <TextField
            label="Nombre visitante"
            variant="outlined"
            className='input_update'
            value={vc.visit.name}
            onChange={(val) => handleChangeNew('visit.name', val)}
          />
          <TextField
            label="Imagen local"
            variant="outlined"
            className='input_update'
            value={vc.local.logo}
            onChange={(val) => handleChangeNew('local.logo', val)}
          />
          <TextField
            label="Imagen visitante"
            variant="outlined"
            className='input_update'
            value={vc.visit.logo}
            onChange={(val) => handleChangeNew('visit.logo', val)}
          />
          <TextField
            label="Nombre evento"
            variant="outlined"
            className='input_update2'
            value={vc.event}
            onChange={(val) => handleChangeNew('event', val)}
          />
          <TextField
            label="Fecha"
            type="datetime-local"
            value={returnFormat(vc.date)}
            className='input_update'
            onChange={(val) => handleChangeNew('date', val)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
          />
          <TextField
            label="Puntos a dar"
            variant="outlined"
            className='input_update'
            value={vc.points}
            onChange={(val) => handleChangeNew('points', val)}
          />
        </Box>
        <Box className='cont_buttons'>
          <CancelRounded
            className='bt_delete'
            onClick={cleanNew}
          />
          <CheckCircleRounded
            className='bt_row'
            onClick={saveNew}
          />
        </Box>
      </Box>
    } else {
      return <Box
        className='bt_new'
        onClick={() => setVc(ov => ({...ov, showNew: true}))}
      >
        Agregar un nuevo evento
      </Box> 
    }
  }

  const firstLoad = async () => {
    try {
      const response = (await firebase.database().ref('guessScore').once('value')).val()
      let arrMatches = []
      if (response) {
        arrMatches = Object.keys(response).map((key) => ({
          ...response[key],
          uid: key,
        }))
      }
      setVc(ov => ({...ov, updates: arrMatches}))
      Model.setData('loading', false)
      Model.setData('opacity', 0.7)
    } catch (e) {
      console.log(e)
      Model.setData('loading', false)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', true)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Layout title='Adivina el marcador' hideBack>
    <Box className="cont_list_guessScore">
      {renderNew()}
      {renderUpdates()}
    </Box>
  </Layout>
}

export default withRouter(Updates);
