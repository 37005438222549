import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Store, StoreProvider } from './hooks/main_store';
import Loading from './components/loading';
import model_class from "./hooks/Model";
import UrlsComponent from './urls';

// Function to init dispatch model classes
function InitHooksClasses() {
  const { dispatch } = React.useContext(Store);
  // Init dispatch for model or models
  React.useEffect(() => {
    model_class.set_dispatch(dispatch)
    return function cleanup () {}
  });
  return (<React.Fragment/>);
}

const App = () => {
  return (
    <>
      <StoreProvider>
        <InitHooksClasses />
        <UrlsComponent />
        <Loading />
      </StoreProvider>
    </>
  );
}

export default hot(App);