import React from 'react';
import { Box } from '@material-ui/core';
import Model from '../../hooks/Model'

function Intro ({history, location}) {
  const firstLoad = () => {
    try {
      const path = location.search.split('?path=')
      if (path[1]) {
        history.replace(`/${path[1]}`)
      } else {
        Model.setData('loading', false)
      }
    } catch (e) {
      Model.setData('loading', false)
    }
  }
  React.useEffect(() => {
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return (
    <Box>
      <h2>You are in other galaxy</h2>
    </Box>
  );
}

export default Intro;
