import React from 'react';
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, TablePagination } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/layout';
import logo from '../../assets/logo.png';
import './ListTournamentPlayers.scss';
import Api from '../../hooks/api';
import moment from 'moment';
import _ from 'lodash';
import Model from '../../hooks/Model'

function ListTournamentPlayers({history}) {
  const [vc, setVc] = React.useState({
    name: '',
    players: []
  })
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [selecteds/*, setSelecteds*/] = React.useState({})

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const selectRow = (player) => {
  //   const urlParams = new URLSearchParams(window.location.search)
  //     const id = urlParams.get('id')
  //   const ref = `${id}_${_.get(player, 'user.uid', '')}`
  //   const local = localStorage.getItem(ref);
  //   if (!local) {
  //     localStorage.setItem(ref, 'done');
  //     setSelecteds((ov) => ({...ov, [ref]: 'done'}))
  //   } else {
  //     localStorage.removeItem(ref);
  //     setSelecteds((ov) => ({...ov, [ref]: null}))
  //   }
  // }

  const isSelected = (player) => {
    const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
    const ref = `${id}_${_.get(player, 'user.uid', '')}`
    const local = localStorage.getItem(ref);
    return selecteds[ref] || local
  }

  const renderPlayers = () => {
    return  <Paper className='table_players'>
      <TableContainer >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Gamer Tag</TableCell>
              <TableCell align="center">Nombre</TableCell>
              <TableCell align="center">Correo</TableCell>
              <TableCell align="center">Teléfono</TableCell>
              <TableCell align="center">Documento</TableCell>
              <TableCell align="center">Adulto</TableCell>
              <TableCell align="center">Fecha</TableCell>
              <TableCell align="center">Ref. Pago</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {vc.players.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((player, index) => (
              <TableRow
                key={`player-${index}`}
                className={`rowCursor ${isSelected(player) ? 'selected' : ''}`}
                // onClick={() => selectRow(player)}
              >
                <TableCell component="th" scope="row" align="center">
                  {_.get(player, 'user.nickName', '-')}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {player.name}
                  {player.isOld === 'no' ? <br/> : null}
                  {player.isOld === 'no' ? `(${player.nameOld})` : null}
                </TableCell>
                <TableCell align="center">
                  {_.get(player, 'user.email', '-')}
                </TableCell>
                <TableCell align="center">
                  {player.contactData}
                </TableCell>
                <TableCell align="center">
                  {player.document}
                  {player.isOld === 'no' ? <br/> : null}
                  {player.isOld === 'no' ? `(${player.documentOld})` : null}
                </TableCell>
                <TableCell align="center">
                  {player.isOld === 'yes' ? 'Si' : 'No'}
                </TableCell>
                <TableCell align="center">
                  {moment(player.refOrder, 'x').format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="center">
                  {player.refOrder}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={vc.players.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage='Usuarios por página'
      />
    </Paper>
  }

  const firstLoad = async () => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      const response = await Api.getTournament(id)
      const players = await Api.getPlayersTournamet({uid: id})
      
      if (response.status === 200 && players.status === 200) {
        setVc({
          name: _.get(response, 'data.name', ''),
          players: _.get(players, 'data.players', [])
        })
        Model.setData('loading', false)
      } else {
        throw new Error('Fail')
      }
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Layout title='Lista de jugadores' subroute='/listTournamentPlayers'>
    <Box className="cont_list_tournament_players">
      <Box className="list_tournament_players">
        <Box className='cont_info'>
          <img alt='tournament' src={logo} className='bg_banner' />
          <Box className='cont_title'>
            <p className='title'> Listado total de los usuarios inscritos al torneo {vc.name} </p>
          </Box>
        </Box>
        <Box className='row_list'>
          {renderPlayers()}
        </Box>
      </Box>
    </Box>
  </Layout>
}

export default withRouter(ListTournamentPlayers);
