import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import logo from '../../assets/logo.png';
import './PreviewTournamentDirect.scss';
import Api from '../../hooks/api';
import moment from 'moment';
import _ from 'lodash';
import Model from '../../hooks/Model'

function PreviewTournamentDirect(props) {
  const [vc, setVc] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [errorMsg, setErrorMsg] = React.useState('')

  const returnNums = (size, typeop) => {
    let numbers = []
    for (let i = 1; i <= size; i++) {
      if (i % 2 !== 0 && typeop === 'mod') {
        numbers.push({
          local: i,
          visit: i + size
        })
      } else if (i % 2 === 0 && typeop === 'res') {
        numbers.push({
          local: i,
          visit: i + size
        })
      }
    } return numbers
  }

  const renderDate = (date) => {
    return date ? moment(date, 'x').format('DD MMM YYYY h:mm a') : 'Fecha por definir'
  }

  const renderPlayer = (index) => {
    return _.get(vc, `preview.groups[${index}].nickName`, '-')
  }

  const renderFirstLeft = (size) => {
    let numbers = returnNums(size, 'mod')
    return <Box className='contPairs'>
      <Box className='title_phase'>Fase 1</Box>
      {numbers.map((pair, index) => <Box
        key={index + '-pairLeft'}
        className='contPair'
      >
        <Box className='numberPair'> {pair.local}</Box>
        <Box className='contNamesPair'>
          <Box className='namePlayer'>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.round1[${pair.local - 1}].players[0]`, ''))}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].first[0]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].second[0]`, '-')}
            </Box>
          </Box>
          <Box className='versusLbl'>VS</Box>
          <Box className='namePlayer'>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.round1[${pair.local - 1}].players[1]`, ''))}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].first[1]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].second[1]`, '-')}
            </Box>
          </Box>
          <Box className='date'>
            {renderDate(_.get(vc, `preview.round1[${pair.local - 1}].date]`, ''))}
          </Box>
        </Box>
        <Box className='joinPair' />
      </Box>)}
    </Box>
  }

  const renderFirstRight = (size) => {
    let numbers = returnNums(size, 'res')
    return <Box className='contPairs right'>
      <Box className='title_phase'>Fase 1</Box>
      {numbers.map((pair, index) => <Box
        key={index + '-pairRight'}
        className='contPair'
      >
        <Box className='joinPair' />
        <Box className='contNamesPair'>
          <Box className='namePlayer'>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].first[0]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].second[0]`, '-')}
            </Box>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.round1[${pair.local - 1}].players[0]`, ''))}
            </Box>
          </Box>
          <Box className='versusLbl'>VS</Box>
          <Box className='namePlayer'>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].first[1]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.round1[${pair.local - 1}].second[1]`, '-')}
            </Box>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.round1[${pair.local - 1}].players[1]`, ''))}
            </Box>
          </Box>
          <Box className='date'>
            {renderDate(_.get(vc, `preview.round1[${pair.local - 1}].date]`, ''))}
          </Box>
        </Box>
        <Box className='numberPair'> {pair.local}</Box>
      </Box>)}
    </Box>
  }

  const renderJoinsLeft = (size, multi, round) => {
    let numbers = returnNums(size, 'mod')
    return <Box className={`contJoins1 ${multi || ''}`} key={round + '-left'}>
      <Box className='title_phase'>Fase {round}</Box>
      {numbers.map((pair, index) => <Box
        key={index + '-pairJoinLeft1-' + size}
        className='contPairJoin1'
      >
        <Box className='joinPair'>
          <Box className='name_local'>
            {renderPlayer(_.get(vc, `preview.round${round}[${index * 2}].players[0]`, '-'))}
          </Box>
          <Box className='goals_local'>
            {_.get(vc, `preview.round${round}[${index * 2}].first[0]`, '-')} | {_.get(vc, `preview.round${round}[${index * 2}].second[0]`, '-')}
          </Box>
          <Box className='date'>
            {renderDate(_.get(vc, `preview.round${round}[${index * 2}].date]`, ''))}
          </Box>
          <Box className='name_visit'>
            {renderPlayer(_.get(vc, `preview.round${round}[${index * 2}].players[1]`, '-'))}
          </Box>
          <Box className='goals_visit'>
            {_.get(vc, `preview.round${round}[${index * 2}].first[1]`, '-')} | {_.get(vc, `preview.round${round}[${index * 2}].second[1]`, '-')}
          </Box>
        </Box>
        <Box className='joinLine' />
      </Box>)}
    </Box>
  }

  const renderJoinsRight = (size, multi, round) => {
    let numbers = returnNums(size, 'mod')
    return <Box className={`contJoins1 right ${multi || ''}`} key={round + '-right'}>
      <Box className='title_phase'>Fase {round}</Box>
      {numbers.map((pair, index) => <Box
        key={index + '-pairJoinRight1-' + size}
        className='contPairJoin1'
      >
        <Box className='joinLine' />
        <Box className='joinPair'>
          <Box className='name_local'>
            {renderPlayer(_.get(vc, `preview.round${round}[${(index * 2) + 1}].players[0]`, '-'))}
          </Box>
          <Box className='goals_local'>
            {_.get(vc, `preview.round${round}[${(index * 2) + 1}].first[0]`, '-')} | {_.get(vc, `preview.round${round}[${(index * 2) + 1}].second[0]`, '--')}
          </Box>
          <Box className='date'>
            {renderDate(_.get(vc, `preview.round${round}[${(index * 2) + 1}].date]`, ''))}
          </Box>
          <Box className='name_visit'>
            {renderPlayer(_.get(vc, `preview.round${round}[${(index * 2) + 1}].players[1]`, '-'))}
          </Box>
          <Box className='goals_visit'>
            {_.get(vc, `preview.round${round}[${(index * 2) + 1}].first[1]`, '-')} | {_.get(vc, `preview.round${round}[${(index * 2) + 1}].second[1]`, '-')}
          </Box>
        </Box>
      </Box>)}
    </Box>
  }

  // const renderFinal = () => {
  //   const groups = ['1er y 2do puesto', '3er y 4to puesto']
  //   return groups.map((group, index) => {
  //     return <Box className='group' key={group + '-group'}>
  //       <Box className='title_group'>{group}</Box>
  //       <table className="quarter">
  //         <tbody>
  //           <tr>
  //             <th>{_.get(vc, `preview.final[${index}].players[0].nickName`, '-')}</th>
  //             <th>vs</th>
  //             <th>{_.get(vc, `preview.final[${index}].players[1].nickName`, '-')}</th>
  //           </tr>
  //           <tr>
  //             <td> {_.get(vc, `preview.final[${index}].first[0]`, '-')} </td>
  //             <td> - </td>
  //             <td> {_.get(vc, `preview.final[${index}].first[1]`, '-')} </td>
  //           </tr>
  //         </tbody>
  //       </table>
  //     </Box>
  //   })
  // }

  const renderRounds = (isLeft) => {
    const limit = _.get(vc, 'preview.limitUsers', 0)
    const startRender = _.get(vc, 'preview.limitUsers', 0) / 4
    if (limit) {
      let qRounds = Math.log10(limit) / Math.log10(2) - 1
      const rounds = new Array(qRounds).fill(() => {})
      const roundsAux = new Array(qRounds).fill('')
      roundsAux.forEach((round, index) => {
        let auxIndex = index + 1
        let clas = auxIndex > 1 ? `d${auxIndex}` : ''
        const quantity = startRender * (index === 0 ? 1 : (1 / (Math.pow(2, index))))
        if (quantity % 2 === 0) {
          if (isLeft) {
            rounds[index] = () => renderJoinsLeft(quantity, clas, `${index + 2}`)
          } else {
            rounds[(rounds.length - 1) - index] = () => renderJoinsRight(quantity, clas, `${index + 2}`)
          }
        }
      })
      return rounds.map((round) => round())
    }
  }

  const renderFinalFixture = () => {
    const rounds = (Object.keys(vc.preview || {}).filter((key) => key.indexOf('round') > -1)).filter((key) => parseInt(key.replace('round', '')) > 0)
    const round = `round${rounds.length}`
    return <Box className='contFinal'>
      <Box className='contPair final'>
        <Box className='contNamesPair'>
          <Box className='lblFinal'>FINAL</Box>
          <Box className='namePlayer'>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.${round}[0].players[0]`, ''))}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[0].first[0]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[0].second[0]`, '-')}
            </Box>
          </Box>
          <Box className='versusLbl'>VS</Box>
          <Box className='namePlayer'>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.${round}[0].players[1]`, ''))}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[0].first[1]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[0].second[1]`, '-')}
            </Box>
          </Box>
        </Box>
        <Box className='date'>
          {renderDate(_.get(vc, `preview.${round}[0].date`, ''))}
        </Box>
      </Box>
      <Box className='lineCenter' />
      <Box className='contPair'>
        <Box className='contNamesPair'>
          <Box className='lblFinal'>TERCER LUGAR</Box>
          <Box className='namePlayer'>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.${round}[1].players[0]`, ''))}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[1].first[0]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[1].second[0]`, '-')}
            </Box>
          </Box>
          <Box className='versusLbl'>VS</Box>
          <Box className='namePlayer'>
            <Box className='name'>
              {renderPlayer(_.get(vc, `preview.${round}[1].players[1]`, ''))}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[1].first[1]`, '-')}
            </Box>
            <Box className='data'>
              {_.get(vc, `preview.${round}[1].second[1]`, '-')}
            </Box>
          </Box>
        </Box>
        <Box className='date'>
          {renderDate(_.get(vc, `preview.${round}[1].date`, ''))}
        </Box>
      </Box>
    </Box>
  }

  const firstLoad = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      const response = await Api.getTournament(id)
      const data = _.get(response, 'data', {})
      if (response.status === 200 && (_.get(data, 'preview.modality', '') === 'direct')) {
        setVc(data)
        setLoading(false)
      } else {
        throw new Error('Fail')
      }
    } catch (e) {
      setErrorMsg('Al parecer estas intentando ver un torneo que no existe.')
      console.log(e)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', false)
    if (props.tournament) {
      setVc(props.tournament)
      setLoading(false)
    } else {
      firstLoad()
    }
    return () => {}
  }, [props.tournament])

  return (
    <Box className="preview_tournament_direct">
      {props.tournament ? null : <Box className='cont_info'>
        <img alt='tournament' src={vc.image} className='bg_banner' />
        <Box className='cont_title'>
          <p className='title'> {vc.name} </p>
          <img alt='tournament' src={logo} className='logo' />
        </Box>
      </Box>}
      {/* {_.get(vc, 'preview.final', '') ? <p className='subtitle'> Gran Final </p> : null}
      {_.get(vc, 'preview.final', '') ? <Box className='row_final'>
        {renderFinal()}
      </Box> : null} */}
      {<p className='subtitle'> Resultados Eliminación Directa </p>}
      <Box className='scroll_cont'>
        <Box className='row_groups'>
          {renderFirstLeft(_.get(vc, 'preview.limitUsers', 0) / 2)}
          {renderRounds(true)}
          {/* {renderJoinsLeft(16, '', '2')}
          {renderJoinsLeft(8, 'd2', '3')}
          {renderJoinsLeft(4, 'd3', '4')}
          {renderJoinsLeft(2, 'd4', '5')} */}
          {renderFinalFixture()}
          {renderRounds(false)}
          {renderFirstRight(_.get(vc, 'preview.limitUsers', 0) / 2)}
        </Box>
      </Box>
      {loading ? <Box className='cont_loading'>
        {errorMsg ? <Box className='error_msg'>
          {errorMsg}<br/>
          Att: La Red Futbolera Gamers
        </Box> : <CircularProgress color='primary' />}
      </Box> : null}
    </Box>
  );
}

export default PreviewTournamentDirect;
