import React from 'react';
import { CircularProgress, Box } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import PreviewDirect from '../PreviewTournamentDirect/PreviewTournamentDirect';
import logo from '../../assets/logo.png';
import Api from '../../hooks/api';
import './PreviewTournament.scss';
import moment from 'moment';
import _ from 'lodash';
import Model from '../../hooks/Model'

function PreviewTournament() {
  const [vc, setVc] = React.useState({})
  const [btsTable, setBtsTable] = React.useState({})
  const [btsMore, setBtsMore] = React.useState({})
  const [btTab, setBtTab] = React.useState(true)
  const [loading, setLoading] = React.useState(true)
  const [errorMsg, setErrorMsg] = React.useState('')

  const toogleBtn = (index, value) => {
    setBtsTable((ov) => ({
      ...ov,
      [index]: value
    }))
  }

  const toogleMore = (index) => {
    setBtsMore((ov) => ({
      ...ov,
      [index]: !ov[index]
    }))
  }

  const returnNick = (number) => {
    return _.get(vc, `preview.groups[${number}].nickName`, '-')
  }

  const renderMatchGroup = (ref, index) => {
    const match = _.get(vc, ref, null)
    if (!match) {
      return null
    } else {
      return <Box className='cont_match' key={ref + '-' + index}>
        <Box className='date_match'>
          {match.date ? moment(match.date, 'x').format('DD MMM YYYY h:mm a') : 'Fecha por definir'}
        </Box>
        <Box className='players_match'>
          <Box className='name_player'>
            {returnNick(match.players[0])}
          </Box>
          <Box className='vs_label'>
            vs
          </Box>
          <Box className='name_player right'>
            {returnNick(match.players[1])}
          </Box>
        </Box>
        <Box className='points_match'>
          {match.first[0] ? `${match.first[0]} - ${match.first[1]}` : '-'}
        </Box>
      </Box>
    }
  }

  const renderGroups = () => {
    if (_.get(vc, `preview`, '')) {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const groups = []
      const sizeGroup = _.get(vc, `preview.limitUsers`, 0) / 4
      const players = [0, sizeGroup, 2 * sizeGroup, 3 * sizeGroup]
      for (let i = 0; i < sizeGroup; i++) {
        groups[i] = letters[i]
      }
      return groups.map((group, index) => {
        const refs = [`preview.round0[${index}].first`, `preview.round0[${index + (sizeGroup * 2)}].first`, `preview.round0[${index}].second`, `preview.round0[${index + sizeGroup}].second`, `preview.round0[${index}].third`, `preview.round0[${index + (sizeGroup * 2)}].third`]
        const playersGroup = (players.map((number) => ({
          ...(_.get(vc, `preview.round0[${number + index}]`, {})),
          group: number + index
        })))
        .sort((a, b) => (b.pts - a.pts) || ((b.gf - b.gc) - (a.gf - a.gc)))
        return <Box className='group' key={group + '-group'}>
          <Box className='title_group' onClick={() => toogleMore(index)}>
            GRUPO {group}
            <Box className='btMore'>
              {!btsMore[index] ? <ExpandMore /> : <ExpandLess />}
            </Box>
          </Box>
          {btsMore[index] ? <Box className='row_bts_table'>
            <Box 
              className={btsTable[index] ? 'bt_table' : 'bt_table active'}
              onClick={() => toogleBtn(index, false)}
            >
              Fechas
            </Box>
            <Box
              className={!btsTable[index] ? 'bt_table' : 'bt_table active'}
              onClick={() => toogleBtn(index, true)}
            >
              Posiciones
            </Box>
          </Box> : null}
          {btsMore[index] ? (btsTable[index] ? <table>
            <tbody>
              <tr>
                <th>Jugador</th>
                <th>PJ</th>
                <th>GF</th>
                <th>GC</th>
                <th>DG</th>
                <th>PTS</th>
              </tr>
              {playersGroup.map((playerInGroup, index2) => <tr key={group + '-group-player' + index2}>
                <td> {returnNick(playerInGroup.group)} </td>
                <td> {_.get(playerInGroup, `pj`, 0)} </td>
                <td> {_.get(playerInGroup, `gf`, 0)} </td>
                <td> {_.get(playerInGroup, `gc`, 0)} </td>
                <td> {_.get(playerInGroup, `gf`, 0) - _.get(playerInGroup, `gc`, 0)} </td>
                <td> {_.get(playerInGroup, `pts`, 0)} </td>
              </tr>)}
            </tbody>
          </table> : <Box className='cont_matches'>
            {refs.map(renderMatchGroup)}
          </Box>) : null}
        </Box>
      })
    } else {
      return null
    }
  }

  // const renderFinal = () => {
  //   const groups = ['1er y 2do puesto', '3er y 4to puesto']
  //   return groups.map((group, index) => {
  //     return <Box className='group' key={group + '-group'}>
  //       <Box className='title_group'>{group}</Box>
  //       <table className="quarter">
  //         <tbody>
  //           <tr>
  //             <th>{_.get(vc, `preview.final[${index}].players[0].nickName`, '-')}</th>
  //             <th>vs</th>
  //             <th>{_.get(vc, `preview.final[${index}].players[1].nickName`, '-')}</th>
  //           </tr>
  //           <tr>
  //             <td> {_.get(vc, `preview.final[${index}].first[0]`, '-')} </td>
  //             <td> - </td>
  //             <td> {_.get(vc, `preview.final[${index}].first[1]`, '-')} </td>
  //           </tr>
  //         </tbody>
  //       </table>
  //     </Box>
  //   })
  // }

  const returnDirect = () => {
    const contInfo = JSON.parse(JSON.stringify(vc))
    contInfo.preview.limitUsers = contInfo.preview.limitUsers / 2
    contInfo.preview.modality = 'direct'
    contInfo.preview.groups = []
    const round1 = _.get(vc, 'preview.round1', '')
    if (round1) { 
      round1.forEach((round) => {
        contInfo.preview.groups[round.players[0]] =  _.get(vc, `preview.groups[${round.players[0]}]`, {})
        contInfo.preview.groups[round.players[1]] =  _.get(vc, `preview.groups[${round.players[1]}]`, {})
      })
    }
    return contInfo
  }

  const firstLoad = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      // if (id === '5' || id === '6') {
      //   return window.location.replace(`https://laredfutbolera.com/tournaments/list/previewTournamentDirect?id=${id}`)
      // }
      const response = await Api.getTournament(id)
      if (response.status === 200 && (_.get(response, 'data.preview.modality', '') === 'groups')) {
        let showGroups = false
        setVc(_.get(response, 'data', {}))
        _.get(response, 'data.preview.round1', []).forEach((cont) => {
          if (!_.get(cont, 'players[0]', '') || !_.get(cont, 'players[0]', '')) {
            showGroups = true
          }
        })
        setBtTab(showGroups)
        setLoading(false)
      } else {
        throw new Error('Fail')
      }
    } catch (e) {
      setErrorMsg('Al parecer estas intentando ver un torneo que no existe.')
      console.log(e)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', false)
    firstLoad()
    return () => {}
  }, [])

  return (
    <Box className="preview_tournament">
      <Box className='cont_info'>
        <img alt='tournament' src={vc.image} className='bg_banner' />
        <Box className='cont_title'>
          <p className='title'> {vc.name} </p>
          <img alt='tournament' src={logo} className='logo' />
        </Box>
      </Box>

      <Box className='row_tabs'>
        <Box
          className={btTab ? 'tab active' : 'tab'}
          onClick={() => setBtTab(true)}
        >
          Fase de Grupos
        </Box>
        <Box
          className={!btTab ? 'tab active' : 'tab'}
          onClick={() => setBtTab(false)}
        >
          Llaves
        </Box>
      </Box>

      {/* {_.get(vc, 'preview.final', '') ? <p className='subtitle'> Gran Final </p> : null}
      {_.get(vc, 'preview.final', '') ? <Box className='row_groups'>
        {renderFinal()}
      </Box> : null} */}
      
      {btTab ? <p className='subtitle'> Fase de grupos - Fechas y Posiciones </p> : null}
      {_.get(vc, `preview`, '') ? (btTab ? <Box className='row_groups'>
        {renderGroups()}
      </Box> : <PreviewDirect
        tournament={returnDirect()}
      />) : null}
      {loading ? <Box className='cont_loading'>
        {errorMsg ? <Box className='error_msg'>
          {errorMsg}<br/>
          Att: La Red Futbolera Gamers
        </Box> : <CircularProgress color='primary' />}
      </Box> : null}
    </Box>
  );
}

export default PreviewTournament;
