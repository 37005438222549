import React from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../utils/firebase';
import { Box, Tooltip, Select, MenuItem } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../hooks/api';
import _ from 'lodash';

const Layout = ({children, title, history, hideBack, closeWindow, subroute}) => {
  const useStyles = makeStyles({
    container: {
      height: '100vh',
      width: '100vw'
    },
    header: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: 'calc(100% - 36px)',
      padding: '12px 18px',
      textAlign: 'center',
      backgroundColor: 'black',
      color: 'white',
      fontWeight: 'bold',
      fontSize: 18,
      zIndex: 2,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    btOut: {
      backgroundColor: 'black',
      padding: '0px 12px',
      cursor: 'pointer'
    },
    btBack: {
      backgroundColor: 'black',
      padding: '0px 12px',
      cursor: 'pointer'
    },
    title: {
      textAlign: 'center',
      flex: 1
    },
    contSelect: {
      padding: '0px 12px',
      border: '1px solid white',
      borderRadius: 6,
      '&:after': {
        display: 'none'
      },
      '&:before': {
        display: 'none'
      },
      '& .MuiSelect-select.MuiSelect-select': {
        color: 'white'
      },
      '& svg': {
        color: 'white'
      }
    }
  })
  const styles = useStyles();
  const [routeSelected] = React.useState(subroute);
  const [type, setType] = React.useState('direct');
  const logout = () => {
    const response = window.confirm('¿Deseas cerrar sesión?');
    if (response === true) {
      firebase.auth().signOut().then(() => {
        localStorage.removeItem('TokenUserLRFAdmin')
        history.push('/login')
      }).catch(() => {})
    }
  }
  const handleChange = (event) => {
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')
    if (event.target.value !== '/previewTournament') {
      history.push(`${event.target.value}?id=${id}`)
    }  else {
      window.open(`${window.location.origin}${event.target.value}${type === 'direct' ? 'Direct' : ''}?id=${id}`, '_blank')
    }
    // seTrouteSelected(event.target.value);
  };
  const firstLoad = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      const response = await Api.getTournament(id)
      
      if (response.status === 200) {
        setType(_.get(response, 'data.preview.modality', 'direct'))
      } else {
        throw new Error('Fail')
      }
    } catch (e) {
      console.log(e)
    }
  }
  React.useEffect(() => {
    if (subroute) {
      firstLoad()
    }
    return () => {}
    //eslint-disable-next-line
  }, [])
  return <Box className={styles.container}>
    <Box className={styles.header}>
      {hideBack ? null : <Box
        className={styles.btBack}
        onClick={() => {
          if (closeWindow) {
            window.close()
          } else {
            history.push('/tournaments')
          }
        }}
      >
        <Tooltip title="Volver">
          <ArrowBack />
        </Tooltip>
      </Box>}
      <Box className={styles.title}>
        {subroute ? <Select
          value={routeSelected}
          onChange={handleChange}
          className={styles.contSelect}
        >
          <MenuItem value={'/listTournamentPlayers'}>Lista de jugadores</MenuItem>
          <MenuItem value={'/matchesAdmin'}>Partidos</MenuItem>
          <MenuItem value={'/formTournament'}>Formulario torneo</MenuItem>
          <MenuItem value={'/chatTournament'}>Chat Grupal</MenuItem>
          <MenuItem value={'/previewTournament'}>Fixture</MenuItem>
        </Select> : (title || 'La Red Futbolera')}
      </Box>
      
      <Box className={styles.btOut} onClick={logout}>
        <Tooltip title="Cerrar sesión">
          <ExitToApp />
        </Tooltip>
      </Box>
    </Box>
    {children}
  </Box>
}
export default withRouter(Layout);
