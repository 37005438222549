import React from 'react';
import { Box } from '@material-ui/core';
// import Edit from '@material-ui/icons/Edit';
// import Group from '@material-ui/icons/Group';
// import SportsEsports from '@material-ui/icons/SportsEsports';
// import AccountTree from '@material-ui/icons/AccountTree';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/layout';
import Api from '../../hooks/api';
import './ListTournaments.scss';
import moment from 'moment';
import _ from 'lodash';
import Model from '../../hooks/Model'


function ListTournaments({history}) {
  const [vc, setVc] = React.useState([])
  const [state, setState] = React.useState('current')
  const [stats, setStats] = React.useState({
    totalGamers: 0,
    gXboxFifa: 0,
    gXboxPes: 0,
    gPlayFifa: 0,
    gPlayPes: 0,
    gPcFifa: 0,
    gPcPes: 0
  })

  const goLink = (url) => {
    window.open(`${window.location.origin}${url}`, '_blank')
  }

  const renderGroups = () => {
    return vc.filter((a) => {
      if (state === 'current') {
        return a.status === 'available'
      } else if (state === 'draft') {
        return a.status === 'draft' && !a.isClosed
      } else {
        return a.status !== 'available' && a.isClosed
      }
    }).sort((a, b) => parseInt(b.date) - parseInt(a.date)).map((tournament, index) => {
      return <Box
        className='card_tournament'
        key={index + '-tournament'}
        onClick={() => goLink(`/listTournamentPlayers?id=${tournament.uid}`)}
      >
        <img alt='tournament' src={tournament.image} className='img_tournament' />
        <Box className='info_tournament'>
          <p className='name_tournament'>
            <b>{tournament.name}</b>
          </p>
          <p className='name_tournament'>
            <b>Fecha:</b> {moment(tournament.date, 'x').format('DD MMM YYYY')}
          </p>
          <p className='name_tournament'>
            <b>Precio:</b> {tournament.price} <b>Juego:</b> {tournament.isFifa ? 'FIFA' : 'PES'}
          </p>
          <p className='name_tournament'>
            <b>Plataforma:</b> {tournament.isPlay ? 'PS4' : (tournament.isXbox ? 'Xbox' : 'PC')}
          </p>
        </Box>
        {/* <Box className='contActions'>
          <Tooltip title="Editar" onClick={() => history.push(`/formTournament?id=${tournament.uid}`)}>
            <Edit className='icon edit' />
          </Tooltip>
          <Tooltip title="Jugadores" onClick={() => history.push(`/listTournamentPlayers?id=${tournament.uid}`)}>
            <Group className='icon players' />
          </Tooltip>
          <Tooltip title="Partidos"  onClick={() => history.push(`/matchesAdmin?id=${tournament.uid}`)}>
            <SportsEsports className='icon matches' />
          </Tooltip>
          <Tooltip title="Fixture"  onClick={() => goLink(tournament.modality === 'direct' ? `/previewTournamentDirect?id=${tournament.uid}` : `/previewTournament?id=${tournament.uid}`)}>
            <AccountTree className='icon fixture' />
          </Tooltip>
        </Box> */}
      </Box>
    })
  }

  const firstLoad = async () => {
    try {
      const response = await Api.getListTournaments()
      const getStatsGamers = await Api.getStatsGamers()
      if (response.status === 200) {
        setVc(_.get(response, 'data.tournaments', []))
        setStats(_.get(getStatsGamers, 'data', {}))
        Model.setData('loading', false)
      } else {
        throw new Error('Fail')
      }
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', true)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Layout title=' Lista de torneos' hideBack>
    <Box className="cont_list_tournament">
      <Box className="list_tournament">
        <Box className='stats'>
          <Box>Total Gamers: {stats.totalGamers}</Box>
          <Box>Xbox (FIFA: {stats.gXboxFifa} - PES: {stats.gXboxPes})</Box>
          <Box>Play (FIFA: {stats.gPlayFifa} - PES: {stats.gPlayPes})</Box>
          <Box>PC (FIFA: {stats.gPcFifa} - PES: {stats.gPcPes})</Box>
        </Box>
        <Box className='row_btns'>
          <Box
            className={state === 'current' ? 'bt_tournament selected' : 'bt_tournament'}
            onClick={() => setState('current')}
          >
            Actuales
          </Box>
          <Box
            className={state === 'draft' ? 'bt_tournament selected' : 'bt_tournament'}
            onClick={() => setState('draft')}
          >
            Borradores
          </Box>
          <Box
            className={state === 'finished' ? 'bt_tournament selected' : 'bt_tournament'}
            onClick={() => setState('finished')}
          >
            Terminados
          </Box>
          <Box
            className='add_tournament'
            onClick={() => history.push(`/formTournament`)}
          >
            Crear
          </Box>
        </Box>
        <Box className='row_cards'>
          {renderGroups()}
        </Box>
      </Box>
    </Box>
  </Layout>
}

export default withRouter(ListTournaments);
