import React from 'react'
import {Box, Link} from '@material-ui/core'
import Model from '../../hooks/Model'
import page404Styles from './page404Styles'

const Page404 = ({location}) => {
  const styles = page404Styles()

  React.useEffect(() => {
    Model.setData('loading', false)
    return () => {}
    //eslint-disable-next-line
  }, []);

  return <Box className={styles.container}>
    <img
      alt='Error 404'
      className={styles.img}
      src={require('../../assets/images/sad.svg')}
    />
    <p className={styles.text}>
      No hemos encontrado la página que estas buscando <br/><br/><code>{location.pathname}</code><br/><br/>
      <b>ERROR: </b>404
    </p>
    <Link href='/login' className={styles.button}>
      Sácame de aquí
    </Link>
  </Box>
}

export default Page404;
