import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/layout';
import Snakbar from '../../components/snakbar';
import firebase from '../../utils/firebase';
import moment from 'moment';
import './ChatTournament.scss';
import _ from 'lodash';
import Model from '../../hooks/Model'

function MatchRoom ({history}) {
  const [vc, setVc] = React.useState({
    message: '',
    infoRoom: {},
    dataTournament: {},
  })
  const [snak, setSnak] = React.useState({
    openAlert: false,
    variantSnak: 'success',
    messageSnak: '',
  })

  const closeSnak = (value) => {
    setSnak({
      openAlert: value,
      variantSnak: 'success',
      messageSnak: '',
    })
  }

  const handleChange = (label, value) => {
    try {
      if (value.persist) {
        value.persist()
      }
      if (value.target) {
        setVc((ov) => ({...ov, [label]: value.target.value }))
      } else {
        setVc((ov) => ({...ov, [label]: value}))
      }
    } catch (e) {
      // Error
    }
  }

  const sendMsg = () => {
    const { message } = vc
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')
    let lastUpdate = '', msgAux = {}

    if (message) {

      msgAux = {date: moment().format('x'), text: message, by: 'admin', name: 'Administrador' }
      lastUpdate = moment().format('x')

      setVc((ov) => ({...ov, message: ''}))

      firebase.database().ref(`chatRoomsTournaments/${id}`).transaction((post) => {
        if (post) {
          post.messages = [...post.messages, msgAux]
          post.lastUpdate = lastUpdate
        }
        return post
      }, (error) => {
        if (!error) {
          // Nothing
        } else {
          // console.warn(error)
        }
      })
    }
  }

  const returnName = () => {
    return vc.dataTournament.name || 'Torneo sin nombre'
  }

  const returnMessages = () => {
    const messAx = _.get(vc, 'infoRoom.messages', [])
    const mess = messAx.length > 100 ? messAx.slice(messAx.length - 100, messAx.length) : messAx.slice(0, 100)
    return mess.map((msg, index) => {
      return <Box
        className='cont_chat'
        key={'bubble-' + index}
      >
        <Box className={msg.by === 'admin' ? 'cont_bubble admin' : 'cont_bubble visit'}>
          <Box className='nickName'>
            {msg.by === 'admin' ? 'Administrador' :  _.get(msg, 'name', 'Sin GamerTag')}
          </Box>
          <Box className='textMsg'>
            {msg.text}
          </Box>
          <Box className='dateMsg'>
            {moment(msg.date, 'x').format('DD MMM YYYY h:mm a')}
          </Box>
        </Box>
      </Box>
    })
  }

  const firstLoad = async () => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      if (!id) {
        throw new Error('Fail')
      } else {
        const dataTournament = (await firebase.firestore().collection(`tournaments`).doc(id).get()).data()
        if (!dataTournament) {
          throw new Error('No exists')
        }
        firebase.database().ref(`chatRoomsTournaments/${id}`).on('value', (snap) => {
          const response = snap.val()
          if (response) {
            setVc((ov) => ({
              ...ov,
              infoRoom: response,
              dataTournament,
            }))
            Model.setData('loading', false)
          } else {
            firebase.database().ref(`chatRoomsTournaments/${id}`).set({
              "lastUpdate" : moment().format('x'),
              "messages" : [{
                by: 'admin',
                text: '¡Hola jugadores! Bienvenidos al torneo, aquí pueden publicar mensajes para conversar con los demás participantes y ver novedades acerca del progreso del torneo. Éxitos y que se diviertan.',
                date: moment().format('x'),
                name: 'Administrador'
              }]
            }).then(() => {
              setSnak({
                openAlert: true,
                variantSnak: 'success',
                messageSnak: 'Se creó la sala correctamente',
              })
            }).catch(() => {
              setSnak({
                openAlert: true,
                variantSnak: 'error',
                messageSnak: 'No se logró obtener la información de la sala',
              })
            })
          }
        }, (e) => {
          setSnak({
            openAlert: true,
            variantSnak: 'error',
            messageSnak: 'Problemas de conexión, vuelve a intentarlo más tarde.',
          })
          console.log(e)
        })
      }
      setTimeout(() => {
        if (document.getElementById('scroll')) {
          document.getElementById('scroll').scrollTo(0, document.getElementById('scroll').scrollHeight)
        }
      }, 1000);
    } catch (e) {
      setSnak({
        openAlert: true,
        variantSnak: 'error',
        messageSnak: 'Problemas de conexión, vuelve a intentarlo más tarde.',
      })
      console.log(e)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', false)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Layout title='Chat Grupal' subroute='/chatTournament'>
    <Box className="cont_match_players">
      <Box className='date'>
        {returnName()}
      </Box>
      <Box className='scroll' id='scroll'>
        {returnMessages()}
      </Box>
      <Box className='rowInput'>
        <TextField
          label="Ingresa tu mensaje aquí ..."
          fullWidth
          variant="outlined"
          className="input_form"
          multiline
          rowsMax={8}
          value={vc.message}
          onChange={(val) => handleChange('message', val)}
        />
        <Box className='btSend' onClick={sendMsg}>
          Enviar
        </Box>
      </Box>
      <Snakbar
        message={snak.messageSnak}
        open={snak.openAlert}
        handleClose={() => closeSnak(false)}
        variant={snak.variantSnak}
      /> 
    </Box>
  </Layout>
}

export default withRouter(MatchRoom);
