import React from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  Switch,
  FormControlLabel,
  FormControl,
  Button,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import Snakbar from '../../components/snakbar';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/layout';
import logo from '../../assets/logo.png';
import Api from '../../hooks/api';
import './FormTournament.scss';
import moment from 'moment';
import _ from 'lodash';
import Model from '../../hooks/Model'

function FormTournament({history}) {
  const [vc, setVc] = React.useState({
    isClosed: false,
    isEnd: false,
    code: '',
    name: '',
    amount: '',
    order: '0',
    place: '',
    date: '',
    datesDescription: '',
    datesLabel: '',
    description: '',
    fileSelected: null,
    limitUsers: '',
    participants: '0',
    isAvailable: false,
    price: '',
    locationDescription: '',
    linkTerms: 'https://laredfutbolera.com/tournaments/TYC/TYC_GAMERS.pdf',
    linkPay: '',
    linkNews: '',
    isXbox: false,
    isPc: false,
    isPlay: false,
    isPes: false,
    isFifa: false,
    openAlert: false,
    variantSnak: 'error',
    messageSnak: '',
    uid: null,
    status: 'create',
    modality: '',
    image: 'https://laredfutbolera.com/tournaments/'
  })

  // const selectFile = () => {
  //   //
  // }

  const handleChange = (label, value) => {
    try {
      if (value.persist) {
        value.persist()
      }
      const valueDef = value.target ? value.target.value : value
      setVc((ov) => ({...ov, [label]: valueDef}))
      if (label === 'modality') {
        const typeNew = valueDef === 'direct' ? 'previewTournamentDirect' : 'previewTournament' 
        setVc((ov) => ({...ov, linkNews: `https://laredfutbolera.com/tournaments/list/${typeNew}?id=${ov.uid}`}))
      }
    } catch (e) {
      // Error
    }
  }

  const handleChangeToogle = (label, value) => {
    try {
      if (value.target) {
        setVc((ov) => ({...ov, [label]: _.get(value, 'target.checked', false) }))
      } else {
        setVc((ov) => ({...ov, [label]: value}))
      }
    } catch (e) {
      // Error
    }
  }

  const saveForm = async () => {
    if (!vc.name || !vc.amount || !vc.order || !vc.datesDescription || !vc.datesLabel || !vc.description /*|| !vc.fileSelected*/ ||!vc.limitUsers || !vc.price || !vc.place || !vc.locationDescription || !vc.linkTerms || !vc.linkPay || !vc.linkNews || !vc.uid || !vc.date || !vc.participants || !vc.modality || !vc.image) {
      handleChange('variantSnak', 'error')
      handleChange('messageSnak', 'Ingresar toda la información')
      handleChange('openAlert', true)
    } else {
      try {
        Model.setData('loading', true)
        const updateData = vc.status === 'create' ? {
          status: 'create',
          data: {
            created: moment().format('x')
          }
        } : {
          status: 'update',
          data: {
            update: moment().format('x')
          }
        }
        const response = await Api.saveTournament({
          data: {
            date: moment(vc.date, 'DD/MM/YYYY').format('x'),
            isClosed: vc.isClosed,
            isEnd: vc.isEnd,
            name: vc.name,
            amount: parseInt(vc.amount) * 100,
            order: parseInt(vc.order),
            place: vc.place,
            datesDescription: vc.datesDescription,
            datesLabel: vc.datesLabel,
            description: vc.description,
            limitUsers: parseInt(vc.limitUsers),
            participants: parseInt(vc.participants),
            status: vc.isAvailable ? 'available' : 'draft',
            price: vc.price,
            locationDescription: vc.locationDescription,
            linkTerms: vc.linkTerms,
            linkPay: vc.linkPay,
            linkNews: vc.linkNews,
            isXbox: vc.isXbox,
            isPc: vc.isPc,
            isPlay: vc.isPlay,
            isPes: vc.isPes,
            isFifa: vc.isFifa,
            modality: vc.modality,
            type: `${parseInt(vc.limitUsers) / 4}x4`,
            image: vc.image,
            ...updateData.data
          },
          uid: String(vc.uid),
          type: updateData.status
        })
        if (response.status === 200) {
          handleChange('variantSnak', 'success')
          handleChange('messageSnak', 'Torneo guardado éxistosamente')
          handleChange('openAlert', true)
          Model.setData('loading', false)
          if (vc.status === 'create') {
            setTimeout(() => window.location.reload(false), 1000);
          }
        } else {
          throw new Error('Fail')
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const firstLoad = async () => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      if (id) {
        const response = await Api.getInfoTournament(id)
        if (response.status === 200) {
          const data = _.get(response, 'data', null)
          setVc((ov) => ({
            ...ov,
            ...data,
            amount: String(data.amount / 100),
            order: String(data.order),
            participants: String(data.participants),
            date: data.date ? moment(data.date, 'x').format('DD/MM/YYYY') : '',
            uid: id,
            status: 'update',
            isAvailable: data.status === 'available'
          }))
          Model.setData('loading', false)
        } else {
          throw new Error('Fail')
        }
      } else {
        const response = await Api.getNextIdTournament()
        if (response.status === 200) {
          const data = _.get(response, 'data.uid', null)
          const newId = parseInt(data) + 1
          handleChange('uid', newId)
          handleChange('linkPay', `https://laredfutbolera.com/tournaments/list/payment?tournament=${newId}&uid=[USER_ID]`)
          Model.setData('loading', false)
        } else {
          throw new Error('Fail')
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', true)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Layout title='Formulario torneo' subroute='/formTournament'>
    <Box className="cont_form_tournament">
      <Box className="form_tournament">
        <Box className='cont_info'>
          <img alt='tournament' src={logo} className='bg_banner' />
          <Box className='cont_title'>
            <p className='title'> Ingresa la información del torneo (ID: {vc.uid}) </p>
          </Box>
        </Box>
        <Box className='form_create'>
          <TextField
            label="Nombre del torneo"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.name}
            onChange={(val) => handleChange('name', val)}
          />
          <TextField
            label="Cantidad valor en pesos"
            fullWidth
            variant="outlined"
            className="input_form"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={vc.amount}
            onChange={(val) => handleChange('amount', val)}
          />
          <TextField
            label="Orden"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.order}
            onChange={(val) => handleChange('order', val)}
          />
          <TextField
            placeholder="DD/MM/YYYY"
            label="Fecha del torneo"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.date}
            onChange={(val) => handleChange('date', val)}
          />
          <TextField
            label="Rango Fechas"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.datesLabel}
            onChange={(val) => handleChange('datesLabel', val)}
          />
          <TextField
            label="Descripción Fechas"
            fullWidth
            variant="outlined"
            className="input_form"
            multiline
            rowsMax={8}
            value={vc.datesDescription}
            onChange={(val) => handleChange('datesDescription', val)}
          />
          <TextField
            label="Descripción"
            fullWidth
            variant="outlined"
            className="input_form"
            multiline
            rowsMax={8}
            value={vc.description}
            onChange={(val) => handleChange('description', val)}
          />
          {/* <Box className="cont_file">
            <label htmlFor="imgTournament">Selecciona una imagen:</label>
            <input
              type="file"
              id="imgTournament"
              name="imgTournament"
              accept="image/*"
              onChange={selectFile}
            ></input>
          </Box> */}
          <Box className="cont_toogles">
            <Box className="lbl_cont">Juegos</Box>
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isFifa}
                onChange={(val) => handleChangeToogle('isFifa', val)}
              />}
              label="Fifa"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isPes}
                onChange={(val) => handleChangeToogle('isPes', val)}
              />}
              label="Pes"
              labelPlacement="start"
            />
          </Box>
          <Box className="cont_toogles">
            <Box className="lbl_cont">Plataformas</Box>
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isPlay}
                onChange={(val) => handleChangeToogle('isPlay', val)}
              />}
              label="PS4"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isPc}
                onChange={(val) => handleChangeToogle('isPc', val)}
              />}
              label="PC"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isXbox}
                onChange={(val) => handleChangeToogle('isXbox', val)}
              />}
              label="Xbox"
              labelPlacement="start"
            />
          </Box>
          <FormControl variant="outlined" className='cont_select' fullWidth>
            <InputLabel id="select_type-label">Limite de participantes</InputLabel>
            <Select
              labelId="select_type-label"
              id="select_type-"
              value={vc.limitUsers}
              onChange={(val) => handleChange('limitUsers', val)}
            >
              <MenuItem value={16}>16</MenuItem>
              <MenuItem value={32}>32</MenuItem>
              <MenuItem value={64}>64</MenuItem>
              <MenuItem value={128}>128</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Participantes"
            fullWidth
            variant="outlined"
            className="input_form"
            type="number"
            value={vc.participants}
            onChange={(val) => handleChange('participants', val)}
          />
          <TextField
            label="Descripción Locación"
            fullWidth
            variant="outlined"
            className="input_form"
            multiline
            rowsMax={8}
            value={vc.locationDescription}
            onChange={(val) => handleChange('locationDescription', val)}
          />
          <TextField
            label="Ubicación"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.place}
            onChange={(val) => handleChange('place', val)}
          />
          <TextField
            label="Precio texto"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.price}
            onChange={(val) => handleChange('price', val)}
          />
          <FormControl variant="outlined" className='cont_select' fullWidth>
            <InputLabel id="select_type-label">Modalidad</InputLabel>
            <Select
              labelId="select_type-label"
              id="select_type-"
              value={vc.modality}
              onChange={(val) => handleChange('modality', val)}
            >
              <MenuItem value={'direct'}>Directa</MenuItem>
              <MenuItem value={'groups'}>Fase de grupos</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Link imagen"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.image}
            onChange={(val) => handleChange('image', val)}
          />
          <TextField
            label="Link Términos y Condiciones"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.linkTerms}
            onChange={(val) => handleChange('linkTerms', val)}
          />
          <TextField
            label="Link Resultados"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.linkNews}
            onChange={(val) => handleChange('linkNews', val)}
          />
          <TextField
            label="Link pago"
            fullWidth
            variant="outlined"
            className="input_form"
            value={vc.linkPay}
            onChange={(val) => handleChange('linkPay', val)}
          />
          <Box className="cont_toogles">
            <Box className="lbl_cont">Estados</Box>
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isEnd}
                onChange={(val) => handleChangeToogle('isEnd', val)}
              />}
              label="Terminado"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isClosed}
                onChange={(val) => handleChangeToogle('isClosed', val)}
              />}
              label="Cerrado"
              labelPlacement="start"
            />
            <FormControlLabel
              value="start"
              control={<Switch
                color="primary"
                checked={vc.isAvailable}
                onChange={(val) => handleChangeToogle('isAvailable', val)}
              />}
              label="Habilitado"
              labelPlacement="start"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="bt_submit"
            onClick={saveForm}
          >
            Guardar
          </Button>
        </Box>
        <Snakbar
          message={vc.messageSnak}
          open={vc.openAlert}
          handleClose={() => handleChange('openAlert', false)}
          variant={vc.variantSnak}
        />
      </Box>
    </Box>
  </Layout>
}

export default withRouter(FormTournament);
