import apisauce from 'apisauce'
import firebase from '../utils/firebase'

const create = () => {
  const api = apisauce.create({
    // baseURL: 'http://localhost:3001',
    baseURL: 'https://laredfutboleraserver.appspot.com',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    timeout: 20000
  })

  api.axiosInstance.interceptors.request.use(async (config) => {
    firebase.auth()
    await new Promise (resolve => setTimeout(resolve, 500))
    if(firebase.auth().currentUser){
      const token = await firebase.auth().currentUser.getIdToken()
      if(token){
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  const getTournament = (id) => {
    return api.post('/tournaments/getTournament', {id})
  }

  const getInfoTournament = (id) => {
    return api.post('/tournaments/getInfoTournament', {id})
  }

  const getListTournaments = () => {
    return api.get('/tournaments/getListTournaments')
  }

  const getNextIdTournament = () => {
    return api.get('/tournaments/getNextIdTournament')
  }

  const saveTournament = (body) => {
    return api.post('/tournaments/saveTournament', body)
  }

  const getPlayersTournamet = (body) => {
    return api.post('/tournaments/getPlayersTournamet', body)
  }

  const saveGoals = (body) => {
    return api.post('/tournaments/saveGoals', body)
  }

  const saveGoalsGroup = (body) => {
    return api.post('/tournaments/saveGoalsGroup', body)
  }

  const verify = (body) => {
    return api.post('lrf_payments/verify', body)
  }

  const create = (body) => {
    return api.post('lrf_payments/create', body)
  }

  const sendNotificationPhase = (body) => {
    return api.post('tournaments/sendNotification', body)
  }

  const sendNotificationMatch = (body) => {
    return api.post('tournaments/sendNotificationMatch', body)
  }

  const getStatsGamers = (body) => {
    return api.get('tournaments/getStatsGamers', body)
  }

  const verifyBook = (body) => {
    return api.post('bookings/verifyBook', body)
  }

  const get = (url) => api.get(url)

  const post = (url, body) => api.post(url, body)

  // ------
  // Interface
  // ------
  return {
    get,
    post,
    getTournament,
    getListTournaments,
    saveTournament,
    getNextIdTournament,
    getInfoTournament,
    getPlayersTournamet,
    saveGoals,
    verify,
    create,
    sendNotificationPhase,
    sendNotificationMatch,
    saveGoalsGroup,
    getStatsGamers,
    verifyBook
  }
}

const api = create()

export default api
