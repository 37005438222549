import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/database'

firebase.initializeApp({
  apiKey: 'AIzaSyBVhTPf5DuKRBEQ-8P8BCMkryoPtDvlDxk',
  authDomain: 'la-red-futbolera-app.firebaseapp.com',
  databaseURL: 'https://la-red-futbolera-app.firebaseio.com',
  projectId: 'la-red-futbolera-app',
  storageBucket: 'la-red-futbolera-app.appspot.com',
  messagingSenderId: '129734356138',
  appId: '1:129734356138:web:c195e699cb52d17929f4d3',
  measurementId: 'G-4MV8JLBW2E'
})

export default firebase
