import React from 'react';
import { Box, Link } from '@material-ui/core';
import useStyles from './ConfirmPartnerStyles';
import Model from '../../hooks/Model'
import ApiLrf from '../../hooks/api';

const ConfirmPartner = ({history}) => {
  const urlParams = new URLSearchParams(window.location.search)
  // Data to link new partner
  const codeUrl = urlParams.get('code');
  const stateUrl = urlParams.get('state');

  const [vc, setVc] = React.useState({
    message: '',
    isDone: false
  })
  const styles = useStyles();

  const firstLoad = async () => {
    try {
      if (codeUrl && stateUrl) {
        const response = await ApiLrf.post('bookings/verifyOAuthMP', {state: stateUrl, code: codeUrl})
        if (response.ok) {
          setVc(ov => ({...ov, message: 'Inscripción correctamente terminada. Gracias por vincular tu cuenta, en pocos minutos quedará configurada tu pasarela de reservas en nuestra aplicación. Cualquier duda o inquietud escribir a soporte@laredfutbolera.com', isDone: true}))
        } else {
          throw new Error('No se pudo realizar la acción requerida. ' + response.data)
        }
      } else {
        throw new Error('No se pudo realizar la acción requerida.')
      }
      Model.setData('loading', false)
    } catch (e) {
      setVc(ov => ({...ov, message: String(e)}))
      Model.setData('loading', false)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', true)
    firstLoad()
    //eslint-disable-next-line
  }, [])

  return <Box className={styles.container}>
    <img
      alt='Error 404'
      className={styles.img}
      src={vc.isDone ? require('../../assets/images/check.png') : require('../../assets/images/sad.svg')}
    />
    <p className={styles.text}>
      {vc.message}<br/><br/>
    </p>
    <Link href='https://laredfutbolera.com/#/stadiums' className={styles.button}>
      Volver a la página principal
    </Link>
  </Box>
}

export default ConfirmPartner;

