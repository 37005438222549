import { makeStyles } from '@material-ui/styles';
import colors from '../../utils/colors';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    background: 'white',
    height: '100vh',
    width: '100vw',
  },
  img: {
    width: 180
  },
  text: {
    textAlign: 'center',
    fontSize: 24,
    maxWidth: 390,
    margin: 30
  },
  button: {
    backgroundColor: colors.green,
    padding: '12px 24px',
    borderRadius: 30,
    color: colors.black
  }
});

export default styles;