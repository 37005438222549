import React from 'react';
import { Box, TextField } from '@material-ui/core';
import Snakbar from '../../components/snakbar';
import { withRouter } from 'react-router-dom';
import firebase from '../../utils/firebase';
import { validateUser } from '../../utils/validate';
import useStyles from './LoginStyles';
import Model from '../../hooks/Model'

function Login({history}) {
  const [vc, setVc] = React.useState({
    email: '',
    password: '',
    openAlert: false,
    variantSnak: 'error',
    messageSnak: '',
  })
  const styles = useStyles();
  const handleChange = (label, value) => {
    try {
      if (value.persist) {
        value.persist()
      }
      if (value.target) {
        setVc((ov) => ({...ov, [label]: value.target.value }))
      } else {
        setVc((ov) => ({...ov, [label]: value}))
      }
    } catch (e) {
      // Error
    }
  }
  const send = async () => {
    if (!vc.email || !vc.password) {
      setVc((ov) => ({
        ...ov,
        variantSnak: 'warning',
        messageSnak: 'No dejes campos vacíos',
        openAlert: true
      }))
    } else {
      try {
        Model.setData('loading', true)
        const user = await firebase.auth().signInWithEmailAndPassword(vc.email, vc.password)
        if (validateUser(user.user.uid)) {
          history.push('/tournaments')
        } else {
          throw new Error('No user')
        }
      } catch (error) {
        setVc((ov) => ({
          ...ov,
          variantSnak: 'error',
          messageSnak: 'El usuario es inválido',
          openAlert: true,
          loading: false
        }))
      }
    }
  }

  React.useEffect(() => {
    let unlisten = () => {}
    try {
      Model.setData('loading', true)
      unlisten = firebase.auth().onAuthStateChanged((user) => {
        if (!user) {
          Model.setData('loading', false)
        } else {
          if (validateUser(user.uid)) {
            localStorage.setItem('TokenUserLRFAdmin', user.uid)
            Model.setData('uid', user.uid)
            history.push('/tournaments')
          } else {
            Model.setData('loading', false)
          }
        }
      })
    } catch (e) {
      Model.setData('loading', false)
    }
    return () => unlisten()
    //eslint-disable-next-line
  }, [])

  return (
    <Box className={styles.root}>
      <Box className={styles.form}>
        <Box className={styles.title}>
          Inicio de sesión
        </Box>
        <TextField
          label="Correo electrónico"
          fullWidth
          variant="outlined"
          className={styles.input}
          value={vc.email}
          onChange={(val) => handleChange('email', val)}
        />
        <TextField
          type='password'
          label="Contraseña"
          fullWidth
          variant="outlined"
          className={styles.input}
          value={vc.password}
          onChange={(val) => handleChange('password', val)}
        />
        <Box
          className={styles.button}
          onClick={send}
        >
          Enviar
        </Box>
      </Box>
      <Snakbar
        message={vc.messageSnak}
        open={vc.openAlert}
        handleClose={() => handleChange('openAlert', false)}
        variant={vc.variantSnak}
      /> 
    </Box>
  );
}

export default withRouter(Login);
