import React from 'react';
import { CircularProgress, Box, Radio, RadioGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { setCurrency } from '../../utils/validate';
import Snakbar from '../../components/snakbar';
import Api from '../../hooks/api';
import apisauce from 'apisauce';
import moment from 'moment';
import './Payment.scss';
import _ from 'lodash';
import Model from '../../hooks/Model'

export default function Payment() {
  const [refOrder] = React.useState(moment().format('x'))
  const [showTest, setShowTest] = React.useState(false)
  const [vc, setVc] = React.useState({
    openAlert: false,
    variantSnak: 'error',
    messageSnak: '',
  })
  const [allReq, setAllReq] = React.useState({
    user: {},
    tournament: {},
    description: {}
  })
  const [description, setDescription] = React.useState({})
  const [varForm, setVarForm] = React.useState({
    name: '',
    document: '',
    nameOld: '',
    documentOld: '',
    city: '',
    isOld: '',
    terms: '',
    contactData: ''
  })
  const [loading, setLoading] = React.useState(true)
  const [errorMsg, setErrorMsg] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [textTitle, setTextTitle] = React.useState('Antes de proceder con el pago necesitamos que ingreses los siguientes datos para brindarte la mejor experiencia durante tu participación:')
  const [btPay, setBtPay] = React.useState('Ir a Pagar')
  const [showMsg, setShowMsg] = React.useState(true)
  const [showChecks, setShowChecks] = React.useState(false)
  const [apiWompi, setApiWompi] = React.useState('')
  const [allChecks, setAllChecks] = React.useState({
    connection: false,
    promise: false,
    disconnect: false,
    evidence: false,
    available: false,
    subscription: false,
    hasGame: false,
    allpromise: false
  })
  const api = apisauce.create({
    baseURL: '',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    timeout: 20000
  })

  const cancelProcess = () => {
    if (window.confirm("¿Deseas cancelar tu proceso de inscripción?")) {
      window.location.replace('https://laredfutbolera.com')
    }
  }

  const changeCheck = (ref, e) => {
    if (e.persist) {
      e.persist()
    }
    setAllChecks((ov) => ({
      ...ov,
      [ref]: e.target.checked
    }))
  }

  const sendChecks = () => {
    const {
      connection,
      promise,
      disconnect,
      evidence,
      available,
      subscription,
      hasGame,
      allpromise
    } = allChecks
    if (connection && promise && disconnect
      && evidence && available && subscription
      && hasGame && (allpromise || _.get(allReq, 'tournament.amount', 0) === 0)) {
      setShowChecks(false)
      submitForm()
    } else {
      setVc({
        variantSnak: 'error',
        messageSnak: 'Debes aceptar todos los puntos para continuar',
        openAlert:  true
      })
    }
  }

  const changeInput = (e, ref) => {
    if (e.persist) {
      e.persist()
    }
    setVarForm((ov) => ({
      ...ov,
      [ref]: e.target.value
    }))
  }

  const remove = (id) => {
    if (document.getElementById(id)) {
      document.getElementById(id).remove()
    }
  }

  const setStyle = (id, prop, value) => {
    if (document.getElementById(id)) {
      document.getElementById(id).style[prop] = value
    }
  }

  const setClass = (id, prop, value) => {
    if (document.getElementById(id)) {
      if (prop === 'add') {
        document.getElementById(id).classList.add(value)
      } else {
        document.getElementById(id).classList.remove(value)
      }
    }
  }

  const toogletab = (number) => {
    if (number === 1) {
      setStyle('infoform', 'display', 'block')
      setStyle('infotournament', 'display', 'none')
      setClass('btsub', 'add', 'active')
      setClass('btdetail', 'remove', 'active')
    } else {
      setStyle('infoform', 'display', 'none')
      setStyle('infotournament', 'display', 'block')
      setClass('btsub', 'remove', 'active')
      setClass('btdetail', 'add', 'active')
    }
  }
  const getOs = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // iOS detection
    console.log(userAgent)
    if (/iPad|iPhone|iPod|Macintosh|Mac OS/.test(userAgent) && !window.MSStream) {
      return "iOS";
    } else {
      return "Android";
    }
  }
  const replaceBreak = (children) => {
    return typeof children === 'string' ? String(children).replace(/\\n/g, '\n') : children;
  }
  const downloadApp = () => {
    const linkiOs = 'https://apps.apple.com/us/app/la-red-futbolera/id1487679950?l=es&ls=1';
    const linkAndroid = 'https://play.google.com/store/apps/details?id=com.laredfutbolera';
    window.open(getOs() === 'iOS' ? linkiOs : linkAndroid, '_blank');
  }
  const skipDownload = () => {
    setTextTitle('Antes de proceder con el pago necesitamos que ingreses los siguientes datos para brindarte la mejor experiencia durante tu participación:')
    setStyle('btintro1', 'display', 'none')
    setStyle('btintro2', 'display', 'none')
    setStyle('allinfo', 'display', 'block')
    setStyle('rowbtns', 'display', 'flex')
  }
  const preSubmit = () => {
    if (!varForm.name || !varForm.document || !varForm.city || !varForm.contactData || !varForm.isOld || (varForm.isOld === 'no' && (!varForm.nameOld || !varForm.documentOld))) {
      setVc({
        variantSnak: 'warning',
        messageSnak: 'Ingresa todos los datos',
        openAlert:  true
      })
    } else if (!varForm.terms || varForm.terms === 'false') {
      setVc({
        variantSnak: 'warning',
        messageSnak: 'Acepta los Términos y Condiciones para continuar',
        openAlert:  true
      })
    } else {
      setAllChecks({
        connection: false,
        promise: false,
        disconnect: false,
        evidence: false,
        available: false,
        subscription: false,
        hasGame: false,
        allpromise: false
      })
      setShowChecks(true)
    }
  }
  const submitForm = async () => {
    try {
      // Get the action to complete.
      const urlParams = new URLSearchParams(window.location.search)
      const isTest = urlParams.get('isTest') || 'no';

      let data = {
        isTest,
        ...varForm
      };
      setStyle('allinfo', 'display', 'none')
      setStyle('rowbtns', 'display', 'none')
      setStyle('msg', 'display', 'flex')
      setLoading(true)
      // Process to verify email
      data = {
        ...data,
        tournament: JSON.stringify(allReq.tournament),
        user: JSON.stringify(allReq.user),
        isTest,
        refOrder,
        status: allReq.tournament.amount === 0 ? 'APPROVED' : 'PENDING'
      };
      const reponse = await Api.create(data)
      if (!reponse.ok || reponse.status !== 200) {
        setTitle('No se puede procesar tu inscripción')
        setTextTitle('El torneo ya ha completado los cupos disponibles')
        setStyle('msg', 'display', 'none')
      } else {
        if (allReq.tournament.amount === 0) {
          // Finish register
          setTitle('¡Hecho!')
          setTextTitle('ATENCIÓN futboler@ ya quedaste inscrit@ al torneo, pronto recibirás un email con las próximas instrucciones (Revisar en bandeja de entrada o Correo no deseado si no lo encuentras). Si no te llega el email o tienes algún inconveniente puedes escribirnos a soporte@laredfutbolera.com ¡Nos vemos en las canchas, prepárate!')
          setStyle('msg', 'display', 'none')
        } else {
          // Go to Wompi
          setTitle('¡Hecho!')
          setTextTitle('Ahora serás redireccionado a la página de pagos.')
          setStyle('msg', 'display', 'none')
          document.getElementById("form_wompi").submit()
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setTitle('No se puede procesar tu inscripción')
      setTextTitle('El torneo ya ha completado los cupos disponibles')
    }
  }

  const firstLoad = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const tournament = urlParams.get('tournament');
      const uid = urlParams.get('uid');
      const isTest = urlParams.get('isTest') || 'no';
      const id = urlParams.get('id');
      const env = urlParams.get('env');
      setShowTest(isTest === 'yes')
      if (id) {
        await api.setBaseURL(env === 'test' ? 'https://sandbox.wompi.co/v1/transactions' : 'https://production.wompi.co/v1/transactions')
        const response = await api.get(`/${id}`)
        if (response.status === 200 && _.get(response, 'data.status', _.get(response, 'data.data.status', ''))  === 'APPROVED') {
          setTitle('Transacción completada ID: ' + id);
          setTextTitle('Gracias por confiar en La Red Futbolera. ATENCIÓN futboler@ ya quedaste inscrit@ al torneo, pronto recibirás un email con las próximas instrucciones (Revisar en bandeja de entrada o Correo no deseado si no lo encuentras). Si no te llega el email o tienes algún inconveniente puedes escribirnos a soporte@laredfutbolera.com ¡Nos vemos en las canchas, prepárate!');
        } else {
          setTitle('Transacción rechazada ID: ' + id);
          setTextTitle('Lamentamos que no se haya podido completar tu pago. Si tienes problemas no dudes en escribirnos al correo soporte@laredfutbolera.com.');
          // throw new Error('Fail')
        }
        setShowMsg(false)
      } else if (tournament && uid && isTest) {
        if (isTest === 'no') {
          setApiWompi('pub_prod_8poPBwiXlaZrj06JTMHpeFHucQRX9bBp');
        } else {
          setApiWompi('pub_test_4XGjdTFKhMhKnqAgV1NDxnrR72rOVK7V');
        }
        const response = await Api.verify({tournament, uid})
        try {
          if (!response.ok || response.status !== 200) {
            setAllReq(response.data || {})
            if (_.get(response, 'data.message', '') === 'isRegistered') {
              throw new Error('isRegistered')
            } else {
              throw new Error('Error')
            }
          } else {
            let alldata = {...response.data}
            delete alldata.description
            setAllReq(alldata)
            setBtPay(`Ir a pagar ${setCurrency((_.get(alldata, 'tournament.amount', 0) / 100))}`)
            changeInput({target: {value: _.get(alldata, 'user.name','')}}, 'name')
            setDescription({
              ...response.data.description,
              description: replaceBreak(response.data.description.description),
              datesDescription: replaceBreak(response.data.description.datesDescription),
              locationDescription: replaceBreak(response.data.description.locationDescription)
            })
            if (uid === 'external') {
              setTextTitle('¡Hola futboler@!\n\nNuestra plataforma nos informa que aún no te encuentras registrado/a en la aplicación oficial de La Red Futbolera®, puedes avanzar con la inscripción sin inconvenientes, pero te sugerimos descargarla y crear tu perfil de jugador para acceder a este y muchos más beneficios de la red.')
              setStyle('btintro1', 'display', 'block')
              setStyle('btintro2', 'display', 'block')
            } else {
              if (_.get(response.data, 'tournament.amount', '') === 0) {
                setBtPay('Inscribirme');
                setTextTitle('Antes de proceder con la inscripción necesitamos que ingreses los siguientes datos para brindarte la mejor experiencia durante tu participación:');
                remove('form_wompi')
                remove('noteRemeber')
                remove('noteWompi')
                remove('btsub')
                remove('btdetail')
              } else {
                remove('noteRemeber2')
              }
              remove('btsub')
              remove('btdetail')
              setStyle('allinfo', 'display', 'block')
              setStyle('rowbtns', 'display', 'flex')
            }
          }
        } catch (e) {
          remove('allinfo')
          remove('form_wompi')
          remove('btsub')
          remove('btdetail')
          remove('btsub')
          remove('btdetail')
          remove('btintro1')
          remove('btintro2')
          if (e.message === 'isRegistered') {
            setTitle('Ya te has registrado en este torneo');
            setTextTitle('No puedes realizar más de una inscripción por usuario. Si tienes problemas con tu inscripción escribe un correo a soporte@laredfutbolera.com');
          } else {
            setTitle('El torneo ya no está disponible');
            setTextTitle('Pueda que ya se hayan completado los cupos o el torneo ha expirado.');
          }
        }
        setShowMsg(false)
      } else {
        setTitle('Error desconocido');
        setTextTitle('No se puede procesar tu solicitud');
        setShowMsg(false)
      }
      setLoading(false)
    } catch (e) {
      setErrorMsg('Problemas de conexión, vuelve a intentarlo más tarde.')
    }
  }

  React.useEffect(() => {
    Model.setData('loading', false)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])

  return <Box className="cont_payment_tournament">
    <Box className="header shadow-1">
      LRF - PAGO INSCRIPCIÓN TORNEO {showTest ? '(TEST MODE)' : ''}
    </Box>
    <Box className="rowbtns" id="rowbtns">
      <button onClick={() => toogletab(1)} className="active" id="btsub">INSCRIPCIÓN</button>
      <button onClick={() => toogletab(2)} id="btdetail">DETALLES DEL TORNEO</button>
    </Box>
    <Box className="info" id="infotournament">
      <h3>
        Descripción
      </h3>
      <p id="descriptiontournament">
        {description.description || ''}
      </p>
      <h3>
        Fechas
      </h3>
      <p id="datestournament">
        {description.datesDescription || ''}
      </p>
      <h3>
        Locación
      </h3>
      <p id="locationtournament">
        {description.locationDescription || ''}
      </p>
    </Box>
    <Box className="info" id="infoform">
      <h3 id="title">
        {title || `Formulario de inscripción ${_.get(allReq, 'tournament.name', '')}`}
      </h3>
      <p id="text">
        {textTitle}
      </p>
      <button onClick={downloadApp} className="nobg" id="btintro1">Descargar aplicación</button>
      <button onClick={skipDownload} id="btintro2">Continuar con inscripción</button>
      <Box id="allinfo">
        <p>Nombre completo</p>
        <input
          autoComplete="off"
          value={varForm.name}
          onChange={(e) => changeInput(e, 'name')}
        />
        <p>Correo electrónico: <b><span id="contactDataEmail">
          {_.get(allReq, 'user.email', '')}
        </span></b><br/></p>
        <p>Número de documento</p>
        <input
          autoComplete="off"
          value={varForm.document}
          onChange={(e) => changeInput(e, 'document')}
        />
        <p>Ciudad</p>
        <input
          autoComplete="off"
          value={varForm.city}
          onChange={(e) => changeInput(e, 'city')}
        />
        <p id="lblContact">WhatsApp</p>
        <input
          autoComplete="off"
          value={varForm.contactData}
          onChange={(e) => changeInput(e, 'contactData')}
        />
        <p>¿Eres mayor de edad? (Estos datos se validan para la entrega de los premios si eres uno de los ganadores)</p>
        <RadioGroup row aria-label="position"  
          className='row_radios'
          onChange={(e) => changeInput(e, 'isOld')}
          value={varForm.isOld}
        >
          <FormControlLabel
            value="yes"
            control={<Radio color="primary" />}
            label="Si"
            labelPlacement="start"
          />
          <FormControlLabel
            value="no"
            control={<Radio color="primary" />}
            label="No"
            labelPlacement="start"
          />
        </RadioGroup>
        {varForm.isOld === 'no' ?<Box>
          <p>Nombre completo del responsable</p>
          <input
            autoComplete="off"
            value={varForm.nameOld}
            onChange={(e) => changeInput(e, 'nameOld')}
          />
          <p>Número de documento del responsable</p>
          <input
            autoComplete="off"
            value={varForm.documentOld}
            onChange={(e) => changeInput(e, 'documentOld')}
          />
        </Box> : null}
        <Box className='row_radios'>
          <Checkbox
            checked={varForm.terms === 'true'}
            onChange={(e) => changeInput({
              target: {
                value: e.target.checked ? 'true' : 'false'
              }
            }, 'terms')}
            color='primary'
          />
          <Box>
            Acepto los Términos y Condiciones <a
              href={_.get(allReq, 'tournament.linkTerms', '#')}
              rel="noopener noreferrer"
              target='_blank'
            >(Ver aquí)</a>
          </Box>
        </Box>
        <p className="note" id="noteWompi"><span role="img" aria-label="lock">🔒</span> Para brindarles la mejor seguridad y confianza a nuestros usuarios, los pagos se realizan por medio de la plataforma <a href="https://colombia.payu.com/" rel="noopener noreferrer" target='_blank'>Wompi</a>, por lo tanto, La Red Futbolera no almacena ni manipula ninguna información sensible de
          los métodos de pagos de los usuarios.
        </p>
        <p className="note" id="noteRemeber"><b>RECUERDA:</b> Solo dispones de 10 minutos máximo para realizar el pago y asegurar tu reserva, pasado el tiempo tu reserva se tomará como vencida y se liberará el cupo. Si presentas problemas puedes escribir un correo a <span>soporte@laredfutbolera.com</span></p>
        <p className="note" id="noteRemeber2"><b>RECUERDA:</b> Al registrarte en un torneo gratuito se confirma de una vez tu inscripción, por lo tanto, si presentas problemas o deseas cancelar tu inscripción puedes escribir un correo a <span>soporte@laredfutbolera.com</span></p>
        <button onClick={preSubmit} id="btPay">
          {btPay}
        </button>
      </Box>
      {showMsg ? <Box className="msg" id="msg">
        Cargando ...
      </Box> : null}
      {allReq.tournament ? <form action="https://checkout.wompi.co/p/" method="GET" id="form_wompi">
        <input type="hidden" name="public-key" id="pub_test" defaultValue={apiWompi} />
        <input type="hidden" name="currency" defaultValue="COP" />
        <input type="hidden" name="amount-in-cents" defaultValue= {allReq.tournament.amount} id="amount_cents" />
        <input type="hidden" name="reference" defaultValue={refOrder} id="ref_order" />
        <input type="hidden" name="redirect-url" defaultValue="https://laredfutbolera.com/payment" />
      </form> : null}
    </Box>
    <Box className="footer">COPYRIGHT © LAREDFUTBOLERA 2020</Box>
    {loading ? <Box className='cont_loading'>
      {errorMsg ? <Box className='error_msg'>
        {errorMsg}<br/>
        Att: La Red Futbolera Gamers
      </Box> : <CircularProgress color='primary' />}
    </Box> : null}
    <Snakbar
      message={vc.messageSnak}
      open={vc.openAlert}
      handleClose={() => setVc({
        ...vc,
        openAlert: false
      })}
      variant={vc.variantSnak}
    />
    {showChecks ? <Box className='modalChecks'>
      <Box className='cont_checks'>
        <Box className='intro_checks'>
          Antes de continuar debes aceptar los siguientes puntos, si no cumples con alguno lo recompendable es que canceles tu proceso ya que puede causar tu descalificación del torneo durante los partidos:
        </Box>
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.available}
              onChange={(e) => changeCheck('available', e)}
              color="primary"
            />
          }
          label={`Tengo disponibilidad en las fechas establecidas del torneo (${_.get(allReq, 'tournament.datesLabel', '')})`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.hasGame}
              onChange={(e) => changeCheck('hasGame', e)}
              color="primary"
            />
          }
          label={`Tengo el juego ${_.get(allReq, 'tournament.isFifa', false) ? 'FIFA' : 'PES'} y cuento con ${_.get(allReq, 'tournament.isPc', false) ? 'PC' : (_.get(allReq, 'tournament.isXbox', false) ? 'consola Xbox' : 'consola Play Station')}`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.subscription}
              onChange={(e) => changeCheck('subscription', e)}
              color="primary"
            />
          }
          label={_.get(allReq, 'tournament.isPc', false) ? 'Tengo computadora con el juego descargado y conexión a internet' : `Tengo una suscripción ${_.get(allReq, 'tournament.isXbox', false) ? 'Xbox Live Gold' : 'Play Station Plus'} y estará vigente durante el torneo`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.connection}
              onChange={(e) => changeCheck('connection', e)}
              name="checkedB"
              color="primary"
            />
          }
          label="Tengo una conexión de mínimo 10 Megas de internet"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.evidence}
              onChange={(e) => changeCheck('evidence', e)}
              color="primary"
            />
          }
          label="Guardaré todo registro fotográfico y/o videos para comprobar cualquier resultado o inconveniente y se los enviaré al canal de soporte de La Red Futbolera si es necesario"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.disconnect}
              onChange={(e) => changeCheck('disconnect', e)}
              color="primary"
            />
          }
          label="Si me desconecto puedo perder el partido a menos que tenga pruebas para soportar un caso fortuito"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.promise}
              onChange={(e) => changeCheck('promise', e)}
              color="primary"
            />
          }
          label="Me comprometo a ofrecer un juego limpio y respetuoso durante mi participación"
        />
        {_.get(allReq, 'tournament.amount', 0) > 0 ? <FormControlLabel
          control={
            <Checkbox
              checked={allChecks.allpromise}
              onChange={(e) => changeCheck('allpromise', e)}
              color="primary"
            />
          }
          label="Al aceptar y realizar el pago de la inscripción no se aceptarán devoluciones por incumplimiento a los términos, condiciones y los puntos anteriormente mencionados"
        /> : null}
        <button onClick={sendChecks}>
          Aceptar e inscribirme
        </button>
        <button className='nobg' onClick={cancelProcess}>
          Cancelar
        </button>
        <button className='noborder' onClick={() => setShowChecks(false)}>
          Volver
        </button>
      </Box>
    </Box> : null}
  </Box>
}
