import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom"
import PrivateRoute from './components/privateRoute'
import PreviewTournament from './containers/PreviewTournament/PreviewTournament'
import PreviewTournamentDirect from './containers/PreviewTournamentDirect/PreviewTournamentDirect'
import ListTournaments from './containers/ListTournaments/ListTournaments'
import FormTournament from './containers/FormTournament/FormTournament'
import Intro from './containers/Intro/Intro'
import ListTournamentPlayers from './containers/ListTournamentPlayers/ListTournamentPlayers'
import MatchesAdmin from './containers/MatchesAdmin/MatchesAdmin'
import Payment from './containers/Payment/Payment'
import PaymentPayUBooking from './containers/Payment/PaymentPayUBooking'
import PaymentMPBooking from './containers/Payment/PaymentMPBooking'
import ConfirmPartner from './containers/Payment/ConfirmPartner'
import MatchRoom from './containers/MatchRoom/MatchRoom'
import Login from './containers/Login/Login'
import ChatTournament from './containers/ChatTournament/ChatTournament'
import Updates from './containers/Updates/Updates'
import GuessScore from './containers/GuessScore/GuessScore'
import Page404 from './containers/Page404/Page404'

// Create router
const AppRouter = () => {
  // console.log(process.env.NODE_ENV)
  const baseUrl = process.env.NODE_ENV === 'development' ? '/' : '/tournaments/list/'
  return (
    <BrowserRouter basename={baseUrl}>
      <Switch>
        <Route path={'/'} exact component={Intro} />
        <Route path={'/login'} exact component={Login} />

        <PrivateRoute path={'/tournaments'} exact component={ListTournaments} />
        <Route path={'/previewTournament'} exact component={PreviewTournament} />
        <Route path={'/previewTournamentDirect'} exact component={PreviewTournamentDirect} />
        <PrivateRoute path={'/formTournament'} exact component={FormTournament} />
        <PrivateRoute path={'/listTournamentPlayers'} exact component={ListTournamentPlayers} />
        <PrivateRoute path={'/matchesAdmin'} exact component={MatchesAdmin} />
        <Route path={'/payment'} exact component={Payment} />
        <Route path={'/paymentPayuBooking'} exact component={PaymentPayUBooking} />
        <Route path={'/paymentMPBooking'} exact component={PaymentMPBooking} />
        <Route path={'/confirmPartner'} exact component={ConfirmPartner} />
        <PrivateRoute path={'/matchRoom'} exact component={MatchRoom} />
        <PrivateRoute path={'/chatTournament'} exact component={ChatTournament} />
        <PrivateRoute path={'/updatesForm'} exact component={Updates} />
        <PrivateRoute path={'/guessScore'} exact component={GuessScore} />
        
        <Route component={Page404}/>
      </Switch>
    </BrowserRouter>
    );
};

export default AppRouter;
