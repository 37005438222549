const colors = {
  lightblue: '#3fb9d9',
  black: '#000000',
  white: '#FFFFFF',
  lightgray: '#F1F1F1',
  darkgray: '#9D9D9D',
  blackfilter: 'rgba(0, 0, 0, 0.7)',
  red: '#F07173',
  blackfilterlight: 'rgba(0, 0, 0, 0.1)',
  gray: '#aaaaaa',
  whitefilter: 'rgba(255, 255, 255, 0.6)',
  whitefilterAlmost: 'rgba(255, 255, 255, 0.95)',
  greenCake: '#5FE4A5',
  green: '#91EF15',
  yellow: '#f6d71d',
  lightDark: '#263238',
  dark: '#000A12',
  darkGreen: '#247C2F',
  // lightGreen: '#A2FEE0'
  lightGreen: '#91EF15',
  inactiveDark: '#2e3639',
  grayMaps: '#EFEEEC',
  // Auxiliars Colors
  transluscentBlack: '#00000050',
  steel: '#CCCCCC',
  slate: '#8C8C8C',
  blueFb: '#4469B0',
  redGg: '#E4443B',
  txtDeclineBt: '#5E676E',
  declineBt: '#CAD3D8',
  orange: '#F48A4C',
  bgLoader: '#050F1A',
  purple: '#9B2BC6'
};

export default colors;