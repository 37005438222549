import React from 'react';
import { Box, TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Layout from '../../components/layout';
import Snakbar from '../../components/snakbar';
import firebase from '../../utils/firebase';
import Api from '../../hooks/api';
import moment from 'moment';
import './MatchRoom.scss';
import Model from '../../hooks/Model'
import _ from 'lodash';

function MatchRoom ({history}) {
  const [vc, setVc] = React.useState({
    message: '',
    infoRoom: {},
    dataTournament: {},
    groups: []
  })
  const [snak, setSnak] = React.useState({
    openAlert: false,
    variantSnak: 'success',
    messageSnak: '',
  })

  const closeSnak = (value) => {
    setSnak({
      openAlert: value,
      variantSnak: 'success',
      messageSnak: '',
    })
  }

  const handleChange = (label, value) => {
    try {
      if (value.persist) {
        value.persist()
      }
      if (value.target) {
        setVc((ov) => ({...ov, [label]: value.target.value }))
      } else {
        setVc((ov) => ({...ov, [label]: value}))
      }
    } catch (e) {
      // Error
    }
  }

  const sendMsg = () => {
    const { message } = vc
    const urlParams = new URLSearchParams(window.location.search)
    const id = urlParams.get('id')
    const tournament = urlParams.get('tournament')
    const contact = _.get(vc, 'infoRoom.contact', '')
    let lastUpdate = '', msgAux = {}

    if (message) {

      msgAux = {date: moment().format('x'), text: (contact ? `(Motivo: ${contact}) ` : '') + message, by: 'admin' }
      lastUpdate = moment().format('x')

      setVc((ov) => ({...ov, message: ''}))

      firebase.database().ref(`matchroomGamers/${tournament}/${id}`).transaction((post) => {
        if (post) {
          post.messages = [...post.messages, msgAux]
          post.lastUpdate = lastUpdate
          post.contact = null
        }
        return post
      }, (error) => {
        if (!error) {
          Api.sendNotificationMatch({
            uids: [_.get(vc, 'infoRoom.user1.uid', ''), _.get(vc, 'infoRoom.user2.uid', '')]
          }).then(() => {}).catch(() => {})
        } else {
          // console.warn(error)
        }
      })
    }
  }

  const returnDate = () => {
    return vc.dataTournament.date ? moment(vc.dataTournament.date, 'x').format('DD MMM YYYY h:mm a') : 'Por definir'
  }

  const returnMessages = () => {
    const messAx = _.get(vc, 'infoRoom.messages', [])
    const mess = messAx.length > 100 ? messAx.slice(messAx.length - 100, messAx.length) : messAx.slice(0, 100)
    return mess.map((msg, index) => {
      const ifVisit = msg.by === _.get(vc, 'infoRoom.user2.uid', '')
      return <Box
        className='cont_chat'
        key={'bubble-' + index}
      >
        <Box className={msg.by === 'admin' ? 'cont_bubble admin' : (ifVisit ? 'cont_bubble visit': 'cont_bubble')}>
          <Box className='nickName'>
            {msg.by === 'admin' ? 'Administrador' :  _.get(vc, `groups[${_.get(vc, `dataTournament.players[${ifVisit ? 1 : 0}]`, '')}].nickName`, 'Sin GamerTag')}
          </Box>
          <Box className='textMsg'>
            {msg.text}
          </Box>
          <Box className='dateMsg'>
            {moment(msg.date, 'x').format('DD MMM YYYY h:mm a')}
          </Box>
        </Box>
      </Box>
    })
  }

  const firstLoad = async () => {
    try {
      Model.setData('loading', true)
      const urlParams = new URLSearchParams(window.location.search)
      const id = urlParams.get('id')
      const tournament = urlParams.get('tournament')
      const phase = urlParams.get('phase')
      const round = urlParams.get('round')
      if (!id || !tournament || !phase || !round) {
        throw new Error('Fail')
      } else {
        let dataTournament = null
        if (phase === 'round0') {
          const ids = (id.replace('_round0', '')).split('CHAT_TO')
          const auxinfoUser = (await firebase.database().ref(`previewTournaments/${tournament}/round0/${ids[0]}`).once('value')).val()
          if (auxinfoUser.first.players[0] === ids[0] && auxinfoUser.first.players[1] === ids[1]) {
            dataTournament = auxinfoUser.first
          } else if (auxinfoUser.second.players[0] === ids[0] && auxinfoUser.second.players[1] === ids[1]) {
            dataTournament = auxinfoUser.second
          } else {
            dataTournament = auxinfoUser.third
          }
        } else {
          dataTournament = (await firebase.database().ref(`previewTournaments/${tournament}/${phase}/${round}`).once('value')).val()
        }
        if (!dataTournament) {
          throw new Error('No exists')
        }
        const groups = (await firebase.database().ref(`previewTournaments/${tournament}/groups`).once('value')).val()
        firebase.database().ref(`matchroomGamers/${tournament}/${id}`).on('value', (snap) => {
          const response = snap.val()
          if (response) {
            setVc((ov) => ({
              ...ov,
              infoRoom: response,
              dataTournament,
              groups
            }))
            Model.setData('loading', false)
          } else {
            const uidLocal = groups[dataTournament.players[0]].uid
            const uidVisit = groups[dataTournament.players[1]].uid
            if (uidLocal && uidVisit) {
              firebase.database().ref(`matchroomGamers/${tournament}/${id}`).set({
                "lastUpdate" : moment().format('x'),
                "messages" : [{
                  by: 'admin',
                  text: 'Mensaje de bienvenida',
                  date: moment().format('x')
                }],
                "user1" : {
                  "uid" : uidLocal
                },
                "user2" : {
                  "uid" : uidVisit
                }
              }).then(() => {
                setSnak({
                  openAlert: true,
                  variantSnak: 'success',
                  messageSnak: 'Se creó la sala correctamente',
                })
              }).catch(() => {
                setSnak({
                  openAlert: true,
                  variantSnak: 'error',
                  messageSnak: 'No se logró obtener la información de la sala',
                })
              })
            } else {
              setSnak({
                openAlert: true,
                variantSnak: 'error',
                messageSnak: 'No se logró obtener la información de la sala',
              })
            }
          }
        }, (e) => {
          setSnak({
            openAlert: true,
            variantSnak: 'error',
            messageSnak: 'Problemas de conexión, vuelve a intentarlo más tarde.',
          })
          console.log(e)
        })
      }
      setTimeout(() => {
        if (document.getElementById('scroll')) {
          document.getElementById('scroll').scrollTo(0, document.getElementById('scroll').scrollHeight)
        }
      }, 1000);
    } catch (e) {
      setSnak({
        openAlert: true,
        variantSnak: 'error',
        messageSnak: 'Problemas de conexión, vuelve a intentarlo más tarde.',
      })
      console.log(e)
    }
  }

  React.useEffect(() => {
    Model.setData('loading', true)
    firstLoad()
    return () => {}
    //eslint-disable-next-line
  }, [])
  return <Layout title='MatchRoom Gamers' closeWindow>
    <Box className="cont_match_players">
      <Box className='date'>
        Fecha del encuentro: {returnDate()}<br/>
        Marcador Parcial: {_.get(vc, 'infoRoom.partial.local', '')}
      </Box>
      <Box className='row_players'>
        <Box className='player'>
          <Box className='nickName'>
            {_.get(vc, `groups[${_.get(vc, 'dataTournament.players[0]', '')}].nickName`, 'Sin GamerTag')}
          </Box>
          <Box className='points'>Marcador: {_.get(vc, 'infoRoom.points.local', 'Ninguno')} </Box>
          <Box className='points'>Confirmó asistencia: {_.get(vc, 'infoRoom.checkin[0]', false) ? 'Si' : 'No'} </Box>
        </Box>
        <Box className='player visit'>
          <Box className='nickName'>
            {_.get(vc, `groups[${_.get(vc, 'dataTournament.players[1]', '')}].nickName`, 'Sin GamerTag')}
          </Box>
          <Box className='points'>Marcador: {_.get(vc, 'infoRoom.points.visit', 'Ninguno')}</Box>
          <Box className='points'>Confirmó asistencia: {_.get(vc, 'infoRoom.checkin[1]', false) ? 'Si' : 'No'} </Box>
        </Box>
      </Box>
      <Box className='scroll' id='scroll'>
        {returnMessages()}
      </Box>
      {_.get(vc, 'infoRoom.contact', '') ? <Box className='motive'>
        Motivo: {_.get(vc, 'infoRoom.contact', '')}
      </Box> : null}
      <Box className='rowInput'>
        <TextField
          label="Ingresa tu mensaje aquí ..."
          fullWidth
          variant="outlined"
          className="input_form"
          multiline
          rowsMax={8}
          value={vc.message}
          onChange={(val) => handleChange('message', val)}
        />
        <Box className='btSend' onClick={sendMsg}>
          Enviar
        </Box>
      </Box>
      <Snakbar
        message={snak.messageSnak}
        open={snak.openAlert}
        handleClose={() => closeSnak(false)}
        variant={snak.variantSnak}
      /> 
    </Box>
  </Layout>
}

export default withRouter(MatchRoom);
