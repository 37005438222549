import firebase from './firebase';
import _ from 'lodash';

const validateUser = (auxUid, avoidLogout) => {
  const uid = auxUid || _.get(firebase.auth(), 'currentUser.uid', '')
  if (uid === 'rTLeDsc7XbZdEj0QQWZuBS2RQA43'
  || uid === 'ue36FEveXnc9aFe0dJGAgoVp25m1') {
    return true
  } else {
    if (!avoidLogout) {
      firebase.auth().signOut().then(() => {}).catch(() => {})
    }
    return false
  }
};

const setCurrency = (value) => {
  if (!value) {
    return ''
  }
  const allAnumber = (String(parseFloat(value).toFixed(0))).split('.')
  const number = allAnumber[0]
  const lengNumber = number.length - 1
  let entNum = ''
  for (let i = lengNumber; i > -1; i--) {
    let inverseNum = lengNumber - i
    let coma = ((inverseNum > 0) && (inverseNum % 3 === 0)) ? ',' : ''
    entNum =  number[i] + coma + entNum
  }
  return `$${entNum}`
};

export {
  validateUser,
  setCurrency
}